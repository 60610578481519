<template>
	<blockquote class="my-34 my-lg-56">
		<slot />
		<cite class="mt-1 mt-lg-0">
			&mdash; <slot name="author">Gough Whitlam</slot>
		</cite>
	</blockquote>
</template>

<style lang="scss">
blockquote {
	font-size: r(26);
	line-height: r(26);
	font-style: normal;
	font-weight: bold;
	letter-spacing: r(1);

	&.w-75 {
		width: 88% !important;

		@include media-breakpoint-up(lg) {
			width: 75% !important;
		}
	}

	&.w-50 {
		width: 88% !important;

		@include media-breakpoint-up(lg) {
			width: 50% !important;
		}
	}

	@include media-breakpoint-up(lg) {
		font-size: r(44);
		line-height: r(53);
	}

	cite {
		//margin-top: r(75);
		display: block;
		font-size: r(20);
		font-weight: 700;
		font-style: normal;

		a {
			color: #ED1C24;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
</style>
