
import { defineComponent, PropType } from 'vue';
import Lightbox from '@/components/Lightbox.vue';
import Btn from '@/components/Btn.vue';

export default defineComponent({
	components: { Lightbox, Btn },
	props: {
		imagery: {
			type: Array as PropType<string[]>,
			default: () => ([]),
		},
		dyk: {
			type: Boolean,
			default: false,
		},
		impact: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({ modal: false }),
	methods: {
		toggleModal() {
			this.modal = !this.modal;
			if (this.modal) {
				document.querySelector('html')!.style.overflow = 'hidden';
				document.querySelector('body')!.style.overflow = 'hidden';
			} else {
				document.querySelector('html')!.style.overflow = 'auto';
				document.querySelector('body')!.style.overflow = 'auto';
			}
		},
	},
});
