<template>
	<div class="panel15">
		<PanelRight
			:pills="['Labor Party', 'Social Reform', 'Australian Identity']"
			image="assets/images/15-bg.jpg"
		>
			<slot />
			<template #body>
				Whitlam rose to power in the Labor Party despite, or perhaps because of, a period of exceptional political and social upheaval.
			</template>
			<template #button>
				<LightboxButton impact dyk>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							A leader for a changing Labor party
						</h2>

						<p>Whitlam broke the mould of a typical Labor member, disassociated from any particular trade union or religion. When the party split in 1955 as anti-Communist Catholic MPs detached to form the Democratic Labor Party, Gough Whitlam was set apart from party tensions.</p>
						<p>He was able to relate to and engage with Australia’s growing educated, progressive and professional middle class, an increasingly important follower group of the Labor Party.</p>
						<p class="mb-0">
							He rapidly rose within the ranks of the Party, contributing thoughtfully to policy development and local advocacy work and presenting direct and well-researched speeches. This led him to become the deputy Labor Leader in 1960, and Leader in 1967 – a position he held for more than a decade.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;I don’t see myself so much as a pillar of the Church but rather as one of those flying buttresses – outside but supporting the structure and those within, a fellow traveller.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['15.1-gallery.jpg', 'Gough Whitlam (left) addresses a meeting as the Member for Werriwa. Image: National Archives of Australia.'],
								['15.2-gallery.jpg', 'Gough Whitlam in 1952, early in his term as Member for Werriwa. Image: National Archives of Australia.'],
								['15.3-gallery.jpg', 'Gough Whitlam as Labor leader, with deputy leader Lance Barnard in 1969. Image: National Archives of Australia.'],
								['15.4-gallery.jpg', 'Gough Whitlam speaking at an ALP meeting. Image: National Archives of Australia.'],
								['15.0-panel.jpg', 'Prime Minister Gough Whitlam wearing a \'Superman\' symbol on his shirt at Sydney Airport in 1974. Image: News Limited.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								Whitlam was focused on making profound change. In a speech to the Victorian ALP Conference in 1967 after he was elected leader of the Labor Party, he said, “I did not seek and do not want the leadership of Australia's largest pressure group. I propose to follow the traditions of those of our leaders who have seen the role of our Party as striving to achieve, and achieving, the National Government of Australia.”
							</p>
						</DidYouKnow>

						<Impact>
							<ul>
								<li>By relating to the educated and open-minded middle classes, Whitlam secured political success. He was able to establish support for his progressive vision for the nation.  </li>
								<li>He outlined his mission to modernise the Labor Party, with an emphasis on social justice, equal opportunity, Indigenous rights and equitable services in Australia’s growing cities, in his iconic ‘It’s time’ speech, for Labor’s return to power. </li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelRight>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row mb-lg-34">
					<div class="timelinedot col-12 col-lg-6 offset-lg-6 col-xxl-4 ps-lg-40 ps-xxl-0">
						<Reveal>
							<h3>1960</h3>
							<p class="light lines-1">
								Whitlam becomes Deputy Leader of the Labor Party.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-6 col-xl-5 col-xxl-4 offset-xl-1">
						<Reveal left>
							<h3>1967</h3>
							<p class="light lines-2 mb-lg-0">
								Whitlam elected Labor leader and sets<br class="d-xxl-none"> about reforming policy platform and structure.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelRight from '@/components/PanelRight.vue';
import Impact from '@/components/Impact.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Reveal from '@/components/Reveal.vue';
import Gallery from '@/components/Gallery.vue';
import Quote from '@/components/Quote.vue';

export default {
	components: {
		Gallery,
		Quote,
		DidYouKnow,
		Reveal,
		LightboxButton, Impact, PanelRight,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(194);
			height: r(1232);
			position: absolute;
			top: r(-35);
			left: 41%;
			z-index: 2;
			background: url(~@/assets/images/timeline-14.svg) 0 0 no-repeat;
		}
	}
}
</style>
