<template>
	<div class="footer mt-96 mt-lg-0 py-34 py-lg-56 position-relative zindex-5">
		<footer class="container">
			<div class="row">
				<div class="col-12">
					<a href="https://www.whitlam.org/" target="_blank">
						<img
							src="@/assets/images/logo-footer.svg"
							alt="Whitlam Institute logo"
							class="footer__logo d-block mb-34 mb-lg-56"
						>
					</a>

					<p>
						Explore the work of the Whitlam Institute at <a href="https://www.whitlam.org/" target="_blank">whitlam.org</a>.
						Many images featured are sourced from
						the
						Whitlam Institute’s Prime Ministerial Collection.<br>
						<a href="https://www.whitlam.org/collection" target="_blank">Search and discover thousands of items</a>.
					</p>

					<p>
						The Whitlam Institute acknowledges the Darug people of the Darug nation as the traditional
						owners and
						custodians of the lands on which we work as the first people of this country. We support the
						Uluru
						Statement from the Heart.
					</p>

					<div class="row align-items-center">
						<div class="col-12 col-sm-6 col-lg-8 mb-3 mb-sm-0">
							<p class="mb-0">
								&copy; Whitlam Institute 2022
							</p>
						</div>
						<div class="col-9 col-sm-6 col-lg-4 d-flex justify-content-between justify-content-sm-around mt-34 mt-lg-0">
							<a href="https://www.facebook.com/whitlaminstitute/" target="_blank"><img src="@/assets/images/ico-facebook.svg" alt="Facebook logo"></a>
							<a href="http://instagram.com/thewhitlaminstitute" target="_blank"><img src="@/assets/images/ico-instagram.svg" alt="Instagram logo"></a>
							<a href="https://twitter.com/Whitlam_Inst" target="_blank"><img src="@/assets/images/ico-twitter.svg" alt="Twitter logo"></a>
							<a href="https://www.linkedin.com/company/whitlaminstitute/?originalSubdomain=au" target="_blank"><img src="@/assets/images/ico-linkedin.svg" alt="Linkedin logo"></a>
							<a href="https://www.youtube.com/channel/UCDvOcLmCZ_mknJ8Wag-604w" target="_blank"><img src="@/assets/images/ico-youtube.svg" alt="Youtube logo"></a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<style lang="scss" scoped>
.footer {
	background: $brownish;
	color: white;
	font-size: r(14);

	&__logo {
		max-width: 100%;
	}

	p {
		line-height: normal;
	}

	a {
		color: white;

		&:hover {
			text-decoration: none;
		}
	}
}
</style>
