<template>
	<div class="thecapital">
		<PanelRight
			:pills="['Family', 'Early Life', 'Canberra']"
			image="assets/images/02-bg.jpg"
		>
			<slot />
			<template #body>
				Whitlam grew up in the sparse early settlement of Canberra, with a small population and a big democratic vision.
			</template>
			<template #button>
				<LightboxButton dyk impact>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							Canberra: a new capital for a changing nation
						</h2>
						<p>When Gough Whitlam’s father, Fred, was promoted to Assistant Crown Solicitor, the family joined the exodus of big city, public service families who were moving to Canberra in 1928.</p>
						<p>The newly established capital was set up in a former sheep paddock, but what was being constructed reached far beyond new streets and structures. Canberra represented a neutral base for federal government, essential in building our sense of Australia as a federated nation and embodying an enthusiastic democratic idealism.</p>
						<p>The majority of Canberra’s population was employed in the service of the federal government. Politicians from all parties, public servants and international visitors were all welcome in the Whitlams’ home. This exposure to the city, progressive democratic idealism, and his father’s ongoing principled belief in civic duty continued to impact Gough Whitlam’s outlook.</p>
						<p class="mb-0">
							Whitlam experienced first-hand the positive impact of well-planned public buildings, parklands and roads in Canberra, underpinning his belief in the centralisation of government and the importance of federal involvement in servicing Australia’s cities.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;When the federal government built Canberra ... every new suburb was fully supplied with schools and roads and sewerage. That obviously had an influence on me.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['3.1-gallery.jpg', 'Gough Whitlam and his father look out over Canberra on one of their walks. Image: Whitlam Prime Ministerial Collection.'],
								['3.2-gallery.jpg', 'The Whitlam family home in Canberra. Image: Whitlam Prime Ministerial Collection.'],
								['3.3-gallery.jpg', 'Gough Whitlam (right) with his uncle, George, and sister, Freda, in Canberra in 1928. Image: Whitlam Prime Ministerial Collection.'],
								['3.0-gallery.jpg', 'Sheep grazing in front of Parliament House. Image: National Library of Australia.'],
							]"
						/>

						<DidYouKnow>
							<p>In most Australian cities, responsibilities for infrastructure and amenities are shared across local, state and federal governments. When Canberra was being built, however, the federal government oversaw everything – urban planning, infrastructure, housing, health, education, and other services.</p>
						</DidYouKnow>

						<Impact>
							<p>When in government, Gough Whitlam demonstrated his belief in the value of centralisation and federal coordination of social services in several revolutionary ways:</p>
							<ul>
								<li>Established a national universal healthcare system – Medibank. </li>
								<li>Funded transport, roads, schools, recreation, sewerage and infrastructure projects to improve the quality of life in urban areas. </li>
								<li>Involved the federal government directly in the planning of Australia’s cities, providing federal coordination of Australia’s urban development with the creation of a Department of Urban and Regional Development. </li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelRight>
		<div class="bg-white position-relative zindex-4">
			<section class="thecapital__timeline container py-lg-96">
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 offset-lg-5 col-xxl-4 offset-xxl-4 mb-lg-6 ps-xxl-56">
						<Reveal>
							<h3>1927</h3>
							<p class="light lines-2">
								Fred Whitlam, Gough’s father, is promoted to Assistant Crown Solicitor.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-4 offset-lg-3 ps-lg-56">
						<Reveal>
							<h3>January 1928</h3>
							<p class="light lines-2 mb-0">
								The Whitlam family moves from Sydney to Canberra.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelRight from '@/components/PanelRight.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Quote from '@/components/Quote.vue';
import Gallery from '@/components/Gallery.vue';
import Impact from '@/components/Impact.vue';
import Reveal from '@/components/Reveal.vue';

export default {
	components: {
		Reveal,
		Impact,
		Quote,
		DidYouKnow, LightboxButton, PanelRight,
		Gallery,
	},
};
</script>

<style lang="scss" scoped>
.thecapital {
	&__timeline {
		position: relative;

		@include media-breakpoint-up(lg) {
			&:before {
				content: '';
				display: block;
				width: r(372);
				height: r(694);
				position: absolute;
				top: r(-145);
				left: 23%;
				z-index: 2;
				background: url(~@/assets/images/timeline-02.svg) 0 0 no-repeat;
			}
		}
	}
}
</style>
