<template>
	<div class="early-life">
		<section class="row">
			<div class="col-12 col-lg-7">
				<div
					class="early-life__img bg-parallax"
					:style="`background-image: url(${require('@/assets/images/01.jpg')})`"
				>
					<img src="@/assets/images/01.jpg">
				</div>
			</div>
			<div class="col-12 col-lg-5 position-relative d-flex flex-column justify-content-end align-items-start">
				<h2 class="mt-5 mb-3 mt-lg-3 mb-lg-5">
					<slot />
				</h2>
				<div class="mb-5">
					<Pill>Family</Pill>
					<Pill>Early Life</Pill>
					<Pill>Education</Pill>
				</div>
				<div class="row">
					<div class="col-10 col-lg-8">
						<p>
							Gough Whitlam’s wicked wit, deep voice and brilliant vocabulary were formed during his early years.
						</p>
					</div>
					<div class="col-2 col-lg-4">
						<LightboxButton class="position-relative" impact dyk>
							<template #main>
								<h2 id="main" class="mb-lg-40">
									A fortunate life
								</h2>

								<p>Edward Gough Whitlam was born into an upper middle-class family in Kew, in Melbourne’s northeast, on Tuesday 11 July 1916. His younger sister, Freda, was born in 1920, after the family moved to Mosman, Sydney.</p>
								<p>Gough Whitlam’s father, Fred, was a well-respected public servant of a gentle, quiet nature and impressed upon his children the importance of public service and duty.</p>
								<p>His mother, Martha, greatly influenced Gough Whitlam’s wickedly biting wit and encouraged feminist values. She was also near deaf, which in turn, led Whitlam to develop a deep, precise, slightly exaggerated tone of voice so she could understand him. He later harnessed his voice to become one of Australia’s most outstanding orators.</p>
								<p class="mb-0">
									Despite contrasting personalities, both parents were church-going Protestants who instilled in their children a great level of respect and tolerance for the different religious faiths. They valued learning and self-advancement, always opting for reading over radio.
								</p>

								<Quote class="w-75 mx-auto">
									&ldquo;I have a brother who is articulate and idealistic.&rdquo;
									<template #author>
										Freda Whitlam, sister.
									</template>
								</Quote>

								<Gallery
									:imagery="[
										['2.1-gallery.jpg', 'From left: young Gough, his mother Martha, and Freda Whitlam at their home in Chatswood, NSW, in 1922. Image: Whitlam Prime Ministerial Collection.'],
										['2.2-gallery.jpg', 'School photo of Gough Whitlam from 1923. Image: Whitlam Prime Ministerial Collection.'],
										['2.3-gallery.jpg', 'Gough Whitlam in 1921, around five years of age. Image: Whitlam Prime Ministerial Collection.'],
										['2.0-gallery.jpg', 'Gough Whitlam with his parents, Frederick and Martha, and his sister, Freda, around 1924. Image: Whitlam Prime Ministerial Collection.'],
									]"
								/>

								<DidYouKnow>
									<p>Gough Whitlam’s sister, Freda, called their mother “a gentle feminist”. During Martha’s own wedding ceremony, she is said to have deliberately coughed rather than say the words “and obey” when it came to the vows.</p>
								</DidYouKnow>

								<Impact>
									<ul>
										<li>Whitlam’s father Fred impressed upon him the importance of public service and duty. This ethos was something he carried with him throughout his life and political career.</li>
										<li>Whitlam’s mother Martha encouraged feminist values.</li>
										<li>The Whitlams read widely. Gough Whitlam was a devoted bookworm – and read everything from classical mythology and novels to encyclopaedias and newspapers. Both Gough Whitlam and his sister had a vocabulary and maturity well beyond their years.</li>
									</ul>
								</Impact>
							</template>
						</LightboxButton>
					</div>
				</div>
			</div>
		</section>

		<section class="mt-5">
			<div class="row">
				<div class="timelinedot col-12 col-lg-5 offset-lg-7 early-life__1916">
					<Reveal>
						<h3>11 July 1916</h3>
						<p class="light lines-2">
							Edward Gough Whitlam born in Kew, an upper middle-class suburb of Melbourne.
						</p>
					</Reveal>
				</div>
			</div>
			<div class="row">
				<div class="timelinedot col-12 col-lg-4 offset-lg-2">
					<Reveal left>
						<h3>January 1918</h3>
						<p class="light lines-2">
							The Whitlam family moves to Sydney, first living in Mosman.
						</p>
					</Reveal>
				</div>
			</div>
			<div class="row">
				<div class="timelinedot col-12 col-lg-6 offset-lg-6 early-life__1925">
					<Reveal>
						<h3>1925 &ndash; 1927</h3>
						<p class="light lines-2">
							Whitlam attends Sydney's Knox Grammar School.
						</p>
					</Reveal>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Pill from '@/components/Pill.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import Impact from '@/components/Impact.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		Impact,
		Reveal,
		DidYouKnow, LightboxButton, Pill,
		Quote,
	},
};
</script>

<style scoped lang="scss">
.early-life {
	&__img {
		@include media-breakpoint-up(lg) {
			transform: translateY(-120px);
		}
	}

	&__1916 {
		position: relative;

		@include media-breakpoint-up(lg) {
			&:before {
				content: '';
				display: block;
				width: r(119);
				height: r(1214);
				position: absolute;
				top: r(10);
				left: r(-119 - 22);
				z-index: 3;
				background: url(~@/assets/images/timeline-01.svg) 0 0 no-repeat;
			}
		}
	}

	&__1925 {
		@include media-breakpoint-up(lg) {
			transform: translateX(r(30));
		}
	}
}
</style>
