
import { defineComponent } from 'vue';
import Lightbox from '@/components/Lightbox.vue';

export default defineComponent({
	components: { Lightbox },
	data: () => ({ show: false }),
	mounted() {
		if (window.localStorage.getItem('warning') === null) {
			this.show = true;
			window.localStorage.setItem('warning', 'shown');
		}
	},
});
