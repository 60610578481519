<template>
	<button class="btn">
		<span>
			<slot>+</slot>
		</span>
	</button>
</template>

<style lang="scss" scoped>
.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: r(40);
	height: r(40);
	min-width: r(40);
	font-family: 'Helvetica Neue', sans-serif;
	font-size: r(22);
	line-height: r(22);
	letter-spacing: 1px;
	border: none;
	outline: none;
	border-radius: 50%;
	background: #ED1C24;
	color: white;

	span {
		transform: translateY(-1px);
	}

	@include media-breakpoint-up(lg) {
		width: r(64);
		height: r(64);
		min-width: r(64);
		font-size: r(34);
		line-height: r(32);

		span {
			transform: translateY(-2px);
		}
	}
}
</style>
