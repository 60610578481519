<template>
	<div>
		<PanelRight
			:pills="['RAAF', 'War']"
			image="assets/images/05-bg.jpg"
		>
			<slot />
			<template #body>
				Leaving behind the safety and security of his younger years, Gough Whitlam joined the war effort.
			</template>
			<template #button>
				<LightboxButton dyk impact>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							Surviving in one piece
						</h2>
						<p>September 3, 1939, marks the day Prime Minister Robert Menzies announced Australia was joining allied British forces at war following Germany’s September 1 invasion of Poland that ignited World War II.</p>
						<p>Whitlam was just getting comfortable in his studies, and even considering a career in academia, but the outbreak of war changed everything and threatened the education of thousands of young Australians.</p>
						<p class="mb-0">
							Gough Whitlam joined the Sydney University Regiment of the Army the following month at the age of 23.
						</p>

						<Quote class="mx-auto">
							&ldquo;While they killed you off in the air force, you were more likely to survive in one piece.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['6.1-gallery.jpg', 'Photo taken of Gough Whitlam during his enlistment in the army, 1942. Image: National Archives of Australia.'],
								['6.2-gallery.jpg', 'Gough Whitlam (middle) with fellow No. 13 Squadron crew members who were stationed in Canberra from 1943 to April 1944. Image: Whitlam Prime Ministerial Collection.'],
								['6.3-gallery.jpg', 'A RAAF recruitment poster. Image: Australian War Memorial.'],
								['6.4-gallery.jpg', 'The bombing of Pearl Harbour as front page news in an Australian newspaper.  Image: National Library of Australia.'],
								['6.0-gallery.jpg', 'The bombing of Pearl Harbour, 1971. Image: National Archives and Records Administration.'],
							]"
						/>

						<DidYouKnow>
							Whitlam went on to join the Royal Australian Air Force (RAAF) on December 8, 1941, the day after Japan’s surprise attack on the United States naval base at Pearl Harbour in Hawaii. Whitlam rationalised his decision to enter a force with high mortality rates by morbidly observing that, “While they killed you off in the air force, you were more likely to survive in one piece”.
						</DidYouKnow>

						<Impact>
							<ul>
								<li>Whitlam’s experience in World War II informed his perspective that conscription (forcing young civilians to serve in the army) was “intolerable”. </li>
								<li>He argued that the conscription of troops for the Australian Army had not only divided Australian society but undermined the Army as an organisation. In office, his government abolished conscription and increased troop numbers. </li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelRight>
		<div class="bg-white position-relative zindex-4 redline">
			<kinesis-container tag="section" class="container pt-lg-96 pb-lg-20">
				<div class="row">
					<div class="col-12 col-lg-11 offset-lg-1 col-xl-10 offset-xl-2 col-xxl-9 offset-xxl-3 d-flex align-items-center">
						<kinesis-element :strength="15" type="depth" class="d-none d-lg-block">
							<img src="@/assets/images/soldier.png">
						</kinesis-element>
						<div class="ms-lg-112">
							<Reveal class="timelinedot mb-6 ps-lg-34 ps-xxl-0">
								<h3>1942</h3>
								<p class="light lines-2">
									Whitlam suspended his studies for four years to serve in the RAAF as a navigator.
								</p>
							</Reveal>
							<Reveal class="timelinedot ms-lg-34">
								<h3>22 April 1942</h3>
								<p class="light lines-2">
									Gough and Margaret marry at St Michael's Anglican Church in Vaucluse.
								</p>
							</Reveal>
						</div>
					</div>
				</div>
			</kinesis-container>
		</div>
	</div>
</template>

<script>
import PanelRight from '@/components/PanelRight.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import Impact from '@/components/Impact.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Impact,
		Quote,
		Gallery,
		Reveal,
		DidYouKnow, LightboxButton, PanelRight,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(166);
			height: r(1603);
			position: absolute;
			top: r(-58px);
			left: 53%;
			transform: translateX(-50%);
			z-index: 2;
			background: url(~@/assets/images/timeline-05.svg) 0 0 no-repeat;
		}
	}
}
</style>
