<template>
	<div class="panel18">
		<PanelRight
			:pills="['Legacy', 'Australia in the World']"
			image="assets/images/18-bg.jpg"
		>
			<slot />
			<template #body>
				Following his Australian political career, Whitlam joined the international political stage. Appointed prominently at UNESCO, Whitlam fought both to save the organisation and for the ongoing value and impact of international law-making on progressive national policy-making.
			</template>
			<template #button>
				<LightboxButton impact dyk>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							The work continues
						</h2>

						<p>Gough Whitlam’s father, Fred, had faith in advancing peace, human equality and opportunity which carried through in Gough Whitlam’s work following his parliamentary career. In 1983, Whitlam was appointed Australian Ambassador and Permanent Delegate to the United Nations Educational, Scientific and Cultural Organization (UNESCO). This allowed him to enter the international political arena. His term as Australian Ambassador to UNESCO ended in 1986, but he was engaged for a further three years on the Executive Board of UNESCO and served as vice-president of UNESCO’s World Heritage Committee.</p>
						<p class="mb-0">
							Whitlam also rekindled his love for the academic environment following politics, taking up academic appointments at the Australian National University in Canberra, the University of Adelaide and Harvard University in the United States of America.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;If the brave idea of UNESCO fails, the world will be a more dangerous place.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['19.1-gallery.jpg', 'Gough Whitlam in his Sydney office, 1990. Image: Whitlam Prime Ministerial Collection.'],
								['19.2-gallery.jpg', 'Gough Whitlam addressing the United Nations General Assembly. Image: National Archives of Australia.'],
								['19.3-gallery.jpg', 'Gough Whitlam visiting a heritage site in Tunisia, 1984. Image: Whitlam Prime Ministerial Collection.'],
								['19.4-gallery.jpg', 'Gough Whitlam attending a UNESCO General Conference in Paris, 1983. Image: Whitlam Prime Ministerial Collection.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								Whitlam’s father Fred was also a part of the United Nations. As Commonwealth Crown Solicitor and consultant to the Department of External Affairs, Fred Whitlam contributed to drafts of the United Nations’ Universal Declaration of Human Rights. He later acted as Australia’s representative at the UN Commission on Human Rights.
							</p>
						</DidYouKnow>

						<Impact>
							<ul>
								<li>Whitlam used his influence, political experience, and intellect to vigorously defend UNESCO and its ideals from wider attacks on the United Nations by the then leaders of the United States and United Kingdom, Ronald Reagan and Margaret Thatcher. </li>
								<li>UNESCO shared Whitlam’s alignment with the ‘one nation, one vote’ principle, giving equal power to all nations, including those decolonised.  </li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelRight>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-6 col-xl-5 offset-lg-5 ps-lg-56 mb-lg-34">
						<Reveal>
							<h3>1978 - 1982</h3>
							<p class="light lines-3">
								Whitlam accepts an academic appointment at the Australian National University in Canberra, Harvard University, and the University of Adelaide.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-4 offset-lg-1 col-xl-3 offset-xl-2">
						<Reveal left>
							<h3>1979</h3>
							<p class="light lines-2">
								Whitlam publishes The Truth of the Matter.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 col-xl-4 offset-lg-5 pb-lg-6 ps-lg-56">
						<Reveal>
							<h3>1979</h3>
							<p class="light lines-2">
								Whitlam appointed Visiting Professor of Australian Studies at Harvard University.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-7 offset-lg-5 col-xl-5 ps-xxl-0 mb-lg-34 ps-lg-34">
						<Reveal>
							<h3>1983</h3>
							<p class="light lines-3">
								Whitlam appointed Australian Ambassador and Permanent Delegate to the United Nations Educational, Scientific and Cultural Organization (UNESCO).
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 col-xl-4 mb-lg-20">
						<Reveal left>
							<h3>1985</h3>
							<p class="light lines-4">
								Whitlam becomes a member of the Constitutional Commission, and advocates for the constitutional recognition of Indigenous people and their land rights.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 col-xl-4 offset-lg-5 ps-lg-34 ps-xxl-0">
						<Reveal>
							<h3>1986</h3>
							<p class="light lines-3">
								Whitlam elected to the Executive Board of UNESCO serving as vice-president of UNESCO’s World Heritage Committee.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import DidYouKnow from '@/components/DidYouKnow.vue';
import Impact from '@/components/Impact.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import PanelRight from '@/components/PanelRight.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		Quote,
		Reveal,
		PanelRight,
		LightboxButton,
		Impact, DidYouKnow,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(160);
			height: r(2220);
			position: absolute;
			top: r(-26);
			left: 34%;
			z-index: 2;
			background: url(~@/assets/images/timeline-18-1.svg) 0 0 no-repeat;
		}
	}
}
</style>
