
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		heading: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			required: true,
		},
		button: {
			type: Boolean,
			default: true,
			required: false,
		},
		layout: {
			type: String,
			default: 'right',
			validate: (value: string) => ['right', 'left'].includes(value),
		},
	},
});
