import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09ed07e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row py-lg-34" }
const _hoisted_2 = { class: "col-12 col-lg-5 offset-lg-2 d-flex flex-column justify-content-center" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "d-flex w-100 align-items-center" }
const _hoisted_5 = { class: "m-0 me-34 me-lg-56" }
const _hoisted_6 = { class: "col-lg-4 d-none d-lg-flex" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_kinesis_element = _resolveComponent("kinesis-element")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["brownish bg-brownish mt-96 mb-34 my-lg-0 py-34 px-20 px-lg-0 py-lg-56 position-relative zindex-5", [`brownish--${_ctx.layout}`]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          class: "brownish__heading color-white w-100 text-lg-end",
          textContent: _toDisplayString(_ctx.heading)
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _renderSlot(_ctx.$slots, "button", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_kinesis_element, {
          strength: 15,
          type: "depth"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require(`@/${_ctx.image}`),
              class: "brownish__img d-block ms-lg-5"
            }, null, 8, _hoisted_7)
          ]),
          _: 1
        })
      ])
    ])
  ], 2))
}