<template>
	<div class="panel16">
		<PanelLeft
			:pills="['Australian Identity', 'Feminism', 'Equality']"
			image="assets/images/16-bg.jpg"
		>
			<slot />
			<template #body>
				As an empowered, independent female public figure, Margaret Whitlam came to symbolise the changing place of women in Australian society.
			</template>
			<template #button>
				<LightboxButton dyk impact>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							It is feminine to think
						</h2>

						<p>
							Margaret Whitlam embodied a fundamental change that was occurring within Australian society in the 1960s. She maintained her own independent career and income as a social worker for the Family Welfare Bureau and later for Parramatta District Hospital.
						</p>

						<p class="mb-0">
							These roles brought her face to face with women who were experiencing prejudice and suffering by way of domestic violence, financial dependency on men, constraints on their bodily autonomy through restrictions on birth control and family planning, and archaic divorce law. She was motivated to raise awareness of these issues and empower women.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;We must write our history, reform our language, keep our own names, live our own lives, redefine our god, make our own laws, learn to defend ourselves, demand and get control of our bodies, and affirm that it is feminine to think.&rdquo;
							<template #author>
								Margaret Whitlam
							</template>
						</Quote>

						<Gallery
							:imagery="[
								['16.1-gallery.jpg', 'Margaret Whitlam with Germaine Greer and Susan Ryan at the International Women’s Year conference in Mexico, 1974. Image: National Archives of Australia.'],
								['16.2-gallery.jpg', 'Margaret Whitlam opens the annual toy fair in Sydney in March, 1973. Image: National Library of Australia.'],
								['16.3-gallery.jpg', 'Herbert Cole (Nugget) Coombs, Margaret Whitlam, and Dick Roughsey at a farewell party for Dr Coombs at the Australian Council for the Arts. Image: National Archives of Australia.'],
								['16.4-gallery.jpg', 'A poster marking the International Women\'s Year. Image: Museum of Australian Democracy.'],
								['16.5-gallery.jpg', 'Margaret Whitlam gives a television interview.  Image: State Library of Queensland.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								Margaret spoke candidly on taboo subjects of the time, such as abortion, marijuana, environmental conservation, social acceptance of de-facto relationships, and women’s rights.
							</p>
						</DidYouKnow>

						<Impact>
							<p>Margaret Whitlam served on the Advisory Committee for the International Women’s Year, whose work led to government funding of women’s health centres, refuges, and women’s social services nationwide.</p>
							<p>Some key policies and changes were introduced that revolutionised the lives of Australian women included:</p>
							<ul>
								<li>no-fault divorce,</li>
								<li>the Equal Pay Case, mandating equal pay for women,</li>
								<li>increased oral contraceptives accessibility,</li>
								<li>a single mother’s welfare benefit,</li>
								<li>maternity leave was offered to public servants, and,</li>
								<li>the appointment of the first women’s advisor to the prime minister.</li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelLeft>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 offset-lg-7 col-xxl-4 offset-xxl-6">
						<Reveal>
							<h3>1968</h3>
							<p class="light lines-2">
								Margaret Whitlam becomes an outspoken advocate for women’s issues.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelLeft from '@/components/PanelLeft.vue';
import Quote from '@/components/Quote.vue';
import Impact from '@/components/Impact.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Reveal from '@/components/Reveal.vue';
import Gallery from '@/components/Gallery.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';

export default {
	components: {
		Gallery,
		Reveal,
		DidYouKnow,
		LightboxButton, Impact, Quote, PanelLeft,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(237);
			height: r(1411);
			position: absolute;
			top: r(-128);
			left: 40%;
			z-index: 2;
			background: url(~@/assets/images/timeline-15.svg) 0 0 no-repeat;
		}
	}
}
</style>
