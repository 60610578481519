<template>
	<div :id="noid ? '' : 'didyouknow'" class="dyk w-100 my-20 my-lg-56">
		<h3 class="dyk__heading mb-20">
			Did you know?
		</h3>
		<div class="dyk__body m-0 p-0 light">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		noid: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss">
.dyk {
	color: white;
	background: #282828;
	padding: r(46) r(34);
	border-radius: 0 0 r(105) 0;

	&__heading {
		font-family: $font-family-base;
		font-size: r(28);
		line-height: r(23);

		@include media-breakpoint-up(lg) {
			font-size: r(48);
			line-height: r(46);
		}
	}

	@include media-breakpoint-up(lg) {
		padding: r(54) r(158) r(54) r(64);
		border-radius: 0 0 r(210) 0;
	}

	&__body {
		a {
			color: white;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
</style>
