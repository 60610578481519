<template>
	<div class="panel21">
		<PanelLeft
			:pills="['Election', 'Social Reform', 'Australian Identity']"
			image="assets/images/21-bg.jpg"
		>
			<slot />
			<template #body>
				Capturing the Australian’s public’s imagination with the ‘It’s time’ campaign, the Labor Party, led by Gough Whitlam, swept into office on 2 December, 1972, ending 23 years of conservative government.
			</template>
			<template #button>
				<LightboxButton dyk impact>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							It’s Time
						</h2>

						<p>Sweeping into government with a mandate for change, Whitlam set about translating his hard-won knowledge and wide-ranging experiences into a vision for Australia, implementing a bold and transformative policy agenda known as ‘the Program’.</p>
						<p>And he did not waste any time. Whitlam formed a two-man ministry immediately after the election with his deputy Lance Bernard – the smallest in Australia history – and got straight to work.</p>
						<p>From 1972 to 1975, Whitlam was able to implement a reform agenda we still benefit from today, through healthcare and women’s rights, to education opportunities, national parks, urban renewal programs, and so much more.</p>
						<p class="mb-0">
							By Whitlam’s own estimate, more than half his reform plan was implemented. But the Senate also rejected 93 Bills, triggering the events that led to the dismissal of the Whitlam government on 11 November 1975, by the Queen’s representative, Governor-General, Sir John Kerr. <a href="https://uws.primo.exlibrisgroup.com/permalink/61UWSTSYD_INST/124kthi/alma9927831632101571" target="_blank">The dismissal</a> remains one of the most controversial moments in Australian political history and is the subject of vigorous debate and new research today.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;There are moments in history when the whole fate and future of nations can be decided by a single decision. For Australia, this is such a time.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['18.1-gallery.jpg', 'Gough Whitlam speaking to Australia’s first women’s advisor to the prime minister Elizabeth Reid, and Media Secretary, James Oswin. Image: National Archives of Australia.'],
								['18.2-gallery.jpg', 'Gough Whitlam delivering the ‘It’s time’ speech at Bowman Hall in Blacktown, NSW, 13 November 1972. Image: National Archives of Australia.'],
								['18.3-gallery.jpg', 'The Whitlam Government Ministry. Image: National Archives of Australia.'],
								['18.4-gallery.jpg', 'Voters visit the ALP how-to-vote stand on election day, 2 December 1972. Image: National Archives of Australia.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								Whitlam is the only Australia Prime Minister to be ‘dismissed’ by the Governor-General, and we’re still learning about how it was possible. Secret correspondence between the Governor-General and Buckingham Palace known as the ‘Palace Letters’, stored in the National Archives of Australia, were withheld from the Australian people under the embargo of the Queen. After a protracted High Court battle, led by Whitlam Institute Distinguished Fellow Professor Jenny Hocking, access was granted in 2020, providing the missing piece in the puzzle on the most controversial episode in Australia’s political history.
							</p>
						</DidYouKnow>

						<Impact>
							<p>The Whitlam Government’s rate of change was rapid, 203 bill were passed in the first year and much work was done during his first 12 days as Prime Minister, including:</p>
							<ul>
								<li>Acting on equal pay for women,</li>
								<li>Scrapping contraceptive pill tax,</li>
								<li>Abolishing the death penalty,</li>
								<li>Ending military service conscription,</li>
								<li>Imposing sanctions on apartheid South Africa, and,</li>
								<li>Clearing the way for colour television, among many other changes and reforms.</li>
								<li><a href="https://www.whitlam.org/studying-whitlam" target="_blank">Learn more</a> about what the Whitlam Government achieved in 1071 days in office.</li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelLeft>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 col-xxl-4 offset-lg-6">
						<Reveal>
							<h3>2 December 1972</h3>
							<p class="light lines-3">
								After 23 years of conservative Australian governments, Gough Whitlam is elected Prime Minister of Australia.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row mb-20 mt-20">
					<div class="timelinedot col-12 col-lg-5 col-xxl-3 offset-xxl-2">
						<Reveal left>
							<h3>11 November 1975</h3>
							<p class="light lines-3">
								Gough Whitlam is dismissed by the Governor-General (Sir John Kerr) as Australia faces a constitutional crisis.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-6 offset-lg-5 ps-lg-56">
						<Reveal>
							<h3>31 July 1978</h3>
							<p class="light lines-1">
								Whitlam resigns from Parliament.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelLeft from '@/components/PanelLeft.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Quote from '@/components/Quote.vue';
import Reveal from '@/components/Reveal.vue';
import Impact from '@/components/Impact.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		Impact,
		Reveal,
		Quote,
		LightboxButton,
		DidYouKnow, PanelLeft,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(79);
			height: r(1913);
			position: absolute;
			top: r(-250);
			left: 40%;
			z-index: 2;
			background: url(~@/assets/images/timeline-17.svg) 0 0 no-repeat;
		}
	}
}
</style>
