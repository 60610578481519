<template>
	<div>
		<PanelLeft
			:pills="['Australia in the World', 'RAAF', 'War']"
			image="assets/images/06-bg.jpg"
		>
			<slot />
			<template #body>
				No longer at the periphery of the global conflict, Australia joined the war, dramatically changing the course of millions of Australians’ lives, including Whitlam’s.
			</template>
			<template #button>
				<LightboxButton dyk impact>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							War in the Pacific
						</h2>
						<p>Three months after Pearl Harbor, Darwin was bombed on February 19, 1942, followed by attacks on other Australian towns and cities. Four months later, Gough Whitlam began his RAAF training as a navigator, and was posted on active duty with Squadron 13 the next year.</p>
						<p>Whitlam served as a navigator in the RAAF, covering much of the South Pacific. He conducted hundreds of flights throughout the war, including missions of dangerous low-altitude bombing, convoy escorts and VIP transport flights across the war-tossed seas to Australia’s north.</p>
						<p class="mb-0">
							The heat, oil fumes and turbulent tropical skies made for hard, dangerous, and exhausting work. Some impacts lasted a lifetime, like later-life permanent hearing loss caused by the constant, loud, hammering engine. Gough Whitlam had many close calls, including being hit by Japanese anti-aircraft fire that caused engine failure, and another where the brakes on his plane failed. In both near-misses Whitlam navigated the aircrafts to safety and escaped unharmed.
						</p>

						<Quote>
							&ldquo;He could have got out of it honourably any time he thought things were getting too risky, but he never did.&rdquo;
							<template #author>
								Flight Lieutenant Lex Goudie, Gough Whitlam’s pilot.
							</template>
						</Quote>

						<Gallery
							:imagery="[
								['7.1-gallery.jpg', 'Gough Whitlam at the RAAF base in Cooktown, 1944. Image: Whitlam Prime Ministerial Collection.'],
								['7.2-gallery.jpg', 'Pilot Officer Edward Gough Whitlam on 4 March 1943. Image: Whitlam Prime Ministerial Collection.'],
								['7.3-gallery.jpg', 'A RAAF Lockheed Ventura aircraft similar to the one Whitlam flew in during the war. Image: Australian War Memorial.'],
								['7.4-gallery.jpg', 'A detailed diagram of the RAAF Lockheed Ventura. Image: Australian War Memorial.'],
								['7.0-panel.jpg', 'Portrait of RAAF Squadron 13 taken by Xenophon Castles in 1944. Image: Whitlam Prime Ministerial Collection.'],
							]"
						/>

						<DidYouKnow>
							Whitlam was diligent in his documentation of his flying hours in in-flight logbooks. By the end of the war, he had over 1,000 flying hours, and this was a practice he continued for the rest of his life, recording more than 5,800 flights by the time of his death.
						</DidYouKnow>

						<Impact>
							<ul>
								<li>Reforms enacted by the Whitlam Government would make the Australian military more modern and efficient, by creating a single, unified Department of Defence.  </li>
								<li>The Whitlam Government supported the shift from the strategic doctrine of ‘forward defence’ to the doctrine of ‘continental defence’. </li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelLeft>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-4 offset-lg-4">
						<Reveal left>
							<h3>May 1942</h3>
							<p class="light lines-2">
								Whitlam deployed by the RAAF just weeks after his wedding.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelLeft from '@/components/PanelLeft.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import Impact from '@/components/Impact.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Impact,
		Quote,
		Gallery,
		Reveal,
		DidYouKnow, LightboxButton, PanelLeft,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(167);
			height: r(1213);
			position: absolute;
			top: r(-90);
			left: 55%;
			z-index: 2;
			background: url(~@/assets/images/timeline-06.svg) 0 0 no-repeat;
		}
	}
}
</style>
