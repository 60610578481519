<template>
	<div class="panel21">
		<PanelLeft
			image="assets/images/epilogue-bg.jpg"
		>
			<slot />
			<template #body>
				The Whitlam name is synonymous with political determination, reform, and visionary policymaking. He left an enduring legacy and a recognition that reform is constant, and that progressive change must be driven and informed by the lived experience of the Australian people.
			</template>
		</PanelLeft>
	</div>
</template>

<script>
import PanelLeft from '@/components/PanelLeft.vue';

export default { components: { PanelLeft } };
</script>
