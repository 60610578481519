
import { defineComponent } from 'vue';
import PanelLeft from '@/components/PanelLeft.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Impact from '@/components/Impact.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import Gallery from '@/components/Gallery.vue';

export default defineComponent({
	components: {
		Gallery,
		Quote,
		Reveal,
		Impact, DidYouKnow, LightboxButton, PanelLeft,
	},
});
