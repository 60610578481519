import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import VueKinesis from 'vue-kinesis';
import { createGtm } from '@gtm-support/vue-gtm';

createApp(App)
	.use(store)
	.use(createGtm({ id: 'GTM-NDSSBTJ' }))
	.use(VueKinesis)
	.mount('#app');
