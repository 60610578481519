
import { defineComponent, PropType } from 'vue';
import Btn from '@/components/Btn.vue';
import Pill from '@/components/Pill.vue';
import 'swiper/swiper.scss';

export default defineComponent({
	components: { Pill, Btn },
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		minimal: {
			type: Boolean,
			default: false,
		},
		dyk: {
			type: Boolean,
			default: false,
		},
		impact: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		scrollTo (id: string) {
			// const offsetTop = (document.querySelector(id) as HTMLElement)?.offsetTop;
			// (this.$refs.body as HTMLElement).scrollTop = offsetTop || 0;
			(document.querySelector(id) as HTMLElement)?.scrollIntoView();
		},
	},
});
