import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Lightbox = _resolveComponent("Lightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Btn, {
      class: "lightboxbtn",
      onClick: _ctx.toggleModal
    }, null, 8, ["onClick"]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_Lightbox, {
        imagery: _ctx.imagery,
        dyk: _ctx.dyk,
        impact: _ctx.impact,
        show: _ctx.modal,
        onClose: _ctx.toggleModal
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "main", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["imagery", "dyk", "impact", "show", "onClose"])
    ]))
  ], 64))
}