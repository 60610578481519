<template>
	<div class="school">
		<kinesis-container tag="section">
			<BrownishSegment
				heading="A gifted mind"
				image="assets/images/03.jpg"
			>
				Gough Whitlam’s school years, in both the private and public systems, nourished his passion for learning and shaped his outlook on education.
				<template #button>
					<LightboxButton dyk impact>
						<template #main>
							<h2 id="main" class="mb-lg-40">
								A gifted mind
							</h2>
							<p>Gough Whitlam attended Canberra’s first public school, Telopea Park School, between 1928 and 1931. This co-educational environment fostered his creative expression, despite desperate under-resourcing and overcrowding. His teachers encouraged him to expand his reading shelf with the works of famous playwrights, like Shakespeare, and poets, including Dryden and Pope.</p>
							<p>Before long, Gough Whitlam’s father moved him to the private, all-boys, sports-focused Canberra Grammar School. Whitlam, however, didn’t let the school’s conservative culture stop him from continuing to write and contribute to the school magazine.</p>
							<p>Whitlam was a budding poet and as he grew in confidence, his poetry was increasingly outspoken, and reflective of his political views. One extract spoke of his fascination with “…the verdant vista of the new.”</p>
							<p class="mb-0">
								Despite being a star student with the highest grades, Whitlam was not awarded the school’s prestigious divinity prize because the headmaster did not believe Whitlam’s faith in God was strong enough. In later years, Whitlam would describe himself as a ‘fellow traveller with Christianity’ rather than subscribing to a belief system.
							</p>

							<Quote class="w-50 mx-auto">
								&ldquo;New skies to scale, new paths to pioneer.&rdquo;
								<template #author>
									Gough Whitlam
								</template>
							</Quote>

							<Gallery
								:imagery="[
									['4.1-gallery.jpg', 'A class at Canberra Grammar School. Image: National Archives of Australia.'],
									['4.2-gallery.jpg', 'Gough Whitlam\'s Knox Grammar school report, which notes his “very lucid mind”, 1927. Image: Whitlam Prime Ministerial Collection.'],
									['4.3-gallery.jpg', 'Gough Whitlam with other students at Telopea Park High School. Image: Whitlam Prime Ministerial Collection.'],
									['4.4-gallery.jpg', 'Canberra Grammar School. Image: National Archives of Australia.'],
									['4.0-gallery.jpg', 'Gough Whitlam at Sydney University in the St Paul\'s College Eights for 1939. Image: Whitlam Prime Ministerial Collection.'],
								]"
							/>

							<DidYouKnow>Gough Whitlam repeated his final year multiple times until he was old enough to go to university, earning the school’s highest academic honour of dux three years in a row. The school magazine reflected on Gough Whitlam as someone, “Who, by some strange caprice of Fate, happened to be attending school as a pupil instead of as a master.” </DidYouKnow>

							<Impact>
								<ul>
									<li>Whitlam’s diverse schooling shaped his fundamental belief that all students should have access to high quality state education. He was confident that when well resourced, the public schooling system could positively foster student learning and opportunity. </li>
									<li>As Deputy Leader of the Labor Party, Whitlam also convinced the party to overturn its policy against federal support for non-government schools.  </li>
									<li>As Prime Minister, Whitlam created the Australian Schools Commission to distribute increased federal funding to all schools, according to their needs, and fund new school buildings to reduce overcrowding. </li>
								</ul>
							</Impact>
						</template>
					</LightboxButton>
				</template>
			</BrownishSegment>
		</kinesis-container>

		<section class="school__timeline position-relative bg-white position-relative zindex-5 py-lg-112">
			<div class="row">
				<div class="timelinedot col-12 col-lg-6 col-xl-5 offset-lg-6 zindex-2 ps-lg-34 ps-xxl-0">
					<Reveal>
						<h3>1928 - 1931</h3>
						<p class="light lines-2">
							Whitlam attends Telopea Park School in Canberra, his first experience of public education.
						</p>
					</Reveal>
				</div>
			</div>
			<div class="school__1932 row">
				<div class="timelinedot col-12 col-lg-5 offset-lg-1 col-xxl-4 offset-xxl-2 zindex-2">
					<Reveal left>
						<h3>1932 - 1934</h3>
						<p class="light lines-2">
							Whitlam awarded dux of Canberra Grammar School three years in a row.
						</p>
					</Reveal>
				</div>
			</div>
			<div class="school__1935 row w-100">
				<div class="timelinedot col-12 col-lg-4 offset-lg-6 zindex-2">
					<Reveal>
						<h3>1935</h3>
						<p class="light lines-2">
							Whitlam wins a scholarship to the University of Sydney.
						</p>
					</Reveal>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import BrownishSegment from '@/components/BrownishSegment.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Impact from '@/components/Impact.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Quote,
		Gallery,
		Reveal,
		Impact,
		DidYouKnow, LightboxButton, BrownishSegment,
	},
};
</script>

<style lang="scss" scoped>
.school {
	&__1932 {
		@include media-breakpoint-up(lg) {
			margin-top: r(80);
		}
	}

	&__1935 {
		@include media-breakpoint-up(lg) {
			position: absolute;
			bottom: r(-185);
			margin-left: r(25);
		}
	}

	&__timeline {
		position: relative;

		@include media-breakpoint-up(lg) {
			&:before {
				content: '';
				display: block;
				width: r(421);
				height: r(1667);
				position: absolute;
				top: r(0);
				left: 40%;
				transform: translateX(-50%);
				z-index: 2;
				background: url(~@/assets/images/timeline-03.svg) 0 0 no-repeat;
			}
		}
	}
}
</style>
