<template>
	<div class="intro row justify-content-between g-0">
		<div class="col-12 col-lg-4 d-flex flex-column justify-content-center">
			<Reveal left>
				<Quote>
					&ldquo;Nothing in politics grows in a vacuum... <br class="d-none d-lg-block">I realise I am but a child of my age.&rdquo;
				</Quote>
			</Reveal>
		</div>
		<div class="col-12 col-lg-6 intro__body mb-34 mb-lg-0">
			<p>As a young man, Gough Whitlam absorbed the radical social, economic and political changes that surrounded him, and translated these new thoughts and ideas into a vision for an independent Australian identity. Whitlam’s early experiences became enshrined in the progressive policies he enacted as Australia’s 21st Prime Minister.</p>
			<p>Today, it’s easy to assume Australia has always enjoyed free healthcare, a vibrant multicultural society, access to education, and recognised the importance of equality. And yet, what we take for granted today are all part of the <a href="https://www.whitlam.org/studying-whitlam" target="_blank">hard-won reforms</a> Whitlam adopted when elected in 1972.</p>
			<p>Throughout his lifetime, Whitlam was sensitive to the experiences and hardships of others and aware of his privileged upbringing. He was open to new ideas, thinkers, artists and intellectuals and looked towards what he called the <em>“verdant vista of the new”</em>.</p>
		</div>
	</div>
</template>

<style scoped lang="scss">
.intro {
	position: relative;
	z-index: 2;

	&__body {
		padding: r(40);
		background: #282828;
		background-origin: content-box;
		border-radius: 0 0 0 r(100);
		color: white;

		font-style: normal;
		font-weight: normal;
		font-size: r(16);
		line-height: r(32);
		letter-spacing: r(1);

		a {
			color: white;
			&:hover {
				text-decoration: none;
			}
		}


		@include media-breakpoint-up(lg) {
			padding: r(80);
			font-size: r(22);
			line-height: r(46);
		}
	}
}
</style>

<script>
import Quote from '@/components/Quote.vue';
import Reveal from '@/components/Reveal.vue';
export default { components: { Reveal, Quote } };
</script>
