<template>
	<div id="impacts" class="impact row my-34 my-lg-56">
		<div class="col-12 col-lg-2">
			<h3 class="impact__heading h2 m-lg-0">
				Impact:
			</h3>
		</div>
		<div class="impact__body col-12 col-lg-10 d-flex flex-column justify-content-center">
			<slot />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.impact {
	&__heading {
		color: #CF2D2E;

		@include media-breakpoint-up(lg) {
			writing-mode: vertical-rl;
			transform: rotate(180deg);
		}
	}

	&__body {
		:deep(li) {
			margin-bottom: 1rem;

			@include media-breakpoint-up(lg) {
				line-height: 1.5rem;
			}
		}

		:deep(a) {
			color: #ED1C24;
			&:hover {
				text-decoration: none;
			}
		}

		@include media-breakpoint-up(lg) {
			line-height: r(34);
		}
	}
}
</style>
