<template>
	<div>
		<PanelLeft
			:pills="['First Nations', 'Australian Identity', 'RAAF']"
			image="assets/images/07-bg.jpg"
		>
			<slot />
			<template #body>
				Whitlam comes face to face with injustice in Australia.
			</template>
			<template #button>
				<LightboxButton impact dyk>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							Indigenous injustice
						</h2>

						<p>Gough Whitlam’s time at war took him to Gove and Yirrkala in the Northern Territory, bringing him into close contact with First Nations peoples. The local community lived side by side in peace with the air force members, helping to build the airstrip and teaching airmen how to catch fish.</p>
						<p>Whitlam admired the locals’ strong connection to land, describing the First Nations community as the ‘inhabitants’, and his Squadron 13 as the ‘intruders’. His use of these terms spoke volumes of his understanding about the prejudices Indigenous Australians faced.</p>
						<p class="mb-0">
							Whitlam told of a young, keen Aboriginal ground staff squadron member who was repeatedly denied the right to join the aircrew. He was adamant that the airmen <em>“Were all convinced that his sole disqualification was his race.”</em> Witnessing first-hand indignities and inequalities inflicted by the ‘intruders’, Whitlam developed a deep-rooted passion for justice for the rights of First Nations peoples.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;The settlement and development of Australia has been achieved at the expense of long-established rights of Aboriginal clans.&rdquo;
							<template #author>
								Gough Whitlam
							</template>
						</Quote>

						<Gallery
							:imagery="[
								['8.1-gallery.jpg', 'Photograph taken by Gough Whitlam at Yirrkala Mission, 4 November 1944. Image: Whitlam Prime Ministerial Collection.'],
								['8.2-gallery.jpg', 'Photograph taken by Gough Whitlam of local Aboriginal men, at Yirrkala Mission, 4 November 1944. Image: Whitlam Prime Ministerial Collection.'],
								['8.3-gallery.jpg', 'Land rights demonstration outside Parliament House in Canberra, 30 July 1972. Image: National Library of Australia.'],
								['8.4-gallery.jpg', 'As prime minister, Gough Whitlam poured soil into Gurindji man Vincent Lingiari’s hand to signify the return of his land. Image: Mervyn Bishop.'],
								['8.5-gallery.jpg', 'Photograph taken by Gough Whitlam at Yirrkala Mission, 4 November 1944. Image: Whitlam Prime Ministerial Collection.'],
							]"
						/>

						<DidYouKnow>
							Whitlam was conscious of the negative effects some of the Christian missions imparted, noting they, <em>“taught Aborigines that they were Godless, they were heathens, made them feel inferior… They destroyed Aborigines’ self-respect.”</em>
						</DidYouKnow>

						<Impact>
							<ul>
								<li>In 1972, as leader of the opposition, Gough Whitlam visited the Aboriginal Tent Embassy, and pledged to support land rights and to end discrimination.  </li>
								<li>Whitlam followed through on this promise as Prime Minister, beginning the return of 3,000 square kilometres of traditional land to the Gurindji people, immortalised in the iconic photo of Whitlam pouring sand into Vincent Lingiari’s hands. </li>
								<li>Whitlam also funded Aboriginal legal services, </li>
								<li>Introduced the Northern Territory Aboriginal Land Rights Bill, </li>
								<li>Established the National Aboriginal Consultative Committee, and </li>
								<li>Passed legislation to abolish discriminatory treatment of Aboriginal people. </li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelLeft>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row mb-lg-6">
					<div class="timelinedot col-12 col-lg-6 offset-lg-2 col-xxl-4 offset-xxl-4">
						<Reveal left>
							<h3>1944</h3>
							<p class="light lines-3">
								Whitlam stationed in Gove and Yirrkala in the Northern Territory, sees first-hand the racism and injustice faced by First Nations peoples.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-8 col-xxl-5 offset-xxl-3">
						<Reveal left>
							<h3>1944</h3>
							<p class="light lines-3">
								The Curtin Labor Government’s post-war referendum awakens Whitlam's political consciousness and ignites his life-long passion for constitutional reform as an instrument of social progress.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelLeft from '@/components/PanelLeft.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Impact from '@/components/Impact.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		DidYouKnow,
		Quote,
		Reveal,
		Impact,
		Gallery,
		LightboxButton, PanelLeft,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(209);
			height: r(1609);
			position: absolute;
			top: r(-173);
			left: 61%;
			z-index: 2;
			background: url(~@/assets/images/timeline-07.svg) 0 0 no-repeat;
		}
	}
}
</style>
