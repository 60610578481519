<template>
	<div class="panel17">
		<PanelRight
			:pills="['Australia in the World', 'Trade']"
			image="assets/images/17-bg.jpg"
		>
			<slot />
			<template #body>
				Whitlam reimagined Australia’s place in the world by forging secure relationships within our own region, and developing an independent identity.
			</template>
			<template #button>
				<LightboxButton impact dyk>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							Foreign Relations
						</h2>

						<p>As leader of the Opposition in 1971, Gough Whitlam was one of the first Western leaders to make high-level political contact with the People’s Republic of China, the most populous communist power in the world during the Cold War. The visit with Premier Zhou Enlai demonstrated Whitlam’s ambition to reimagine Australian foreign policy, secure trade relations, and reposition Australia in the world.</p>
						<p>The influence of Cold War fears prevailed over Australian politics at this time, making it a politically risky venture for Whitlam in the lead up to an important election. Then Prime Minister Billy McMahon widely condemned the move, believing it to be damaging to Australia's traditional alliances.</p>
						<p class="mb-0">
							In fact, the bold 1971 visit was a success for Whitlam and for Australia, creating a strong foundation for the increasingly complex bilateral relations between China and Australia. The visit also demonstrated to the Australian public the importance of forging secure relationships within our own region, and the need to develop an independent identity.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;He 1971 visit to China not only paved the way for diplomatic relations ... it opened the way to public support for engagement with Asia on a new basis of equality and mutual respect.&rdquo;
							<template #author>
								Dr FitzGerald, Australia’s first Ambassador to the People’s Republic of China.
							</template>
						</Quote>

						<Gallery
							:imagery="[
								['17.1-gallery.jpg', 'Gough Whitlam with hospital staff during a historic visit to China, 1971. Photography by then ALP Federal Secretary Mick Young, generously donated to the Whitlam Institute by the Young family.'],
								['17.2-gallery.jpg', 'Stephen FitzGerald with a local teacher during a visit to a Commune School, 1971. Photography by then ALP Federal Secretary Mick Young, generously donated to the Whitlam Institute by the Young family.'],
								['17.3-gallery.jpg', 'Team of journalists who accompanied Whitlam on his historic visit to China. Left to right: Derek McKendry (ABC cameraman), John Stubbs (The Sydney Morning Herald), Willie Phua (ABC sound technician), Kenneth Randall (The Australian), Eric Walsh (Australian Financial Review), Philip Koch (ABC correspondent), Alan Barnes (Melbourne Age), Laurie Oakes (Melbourne Sun), David Barnett (Australian Associated Press). Photography by then ALP Federal Secretary Mick Young, generously donated to the Whitlam Institute by the Young family.'],
								['17.4-gallery.jpg', 'Gough Whitlam, Stephen FitzGerald and the ALP delegation visit the Great Wall of China, 1971. Photography by then ALP Federal Secretary Mick Young, generously donated to the Whitlam Institute by the Young family.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								Whitlam's audacious initiative was vindicated when it was revealed that just as Whitlam's ALP delegation was leaving Beijing, US National Security Adviser, Henry Kissinger, was arriving to arrange President Nixon's own meeting with Zhou Enlai. This news immediately proved Gough Whitlam was on the front foot, and that his vision for a forward-looking future was in line with the development of a new world order.
							</p>
						</DidYouKnow>

						<Impact>
							<ul>
								<li>In 1972, within three weeks of taking office, the Whitlam Government negotiated an agreement with the People's Republic of China to establish diplomatic relations with Australia, cementing a radical shift in Australia's outlook on the world, and its region. </li>
								<li>For decades, Australia had looked to China with distrust, anxiety and paranoia. The Whitlam Government's establishment of diplomatic relations allowed a mature (though challenging) cultural, social and economic relationship to develop. </li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelRight>

		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-6 offset-lg-5 col-xxl-4 ps-lg-72">
						<Reveal>
							<h3>3 July 1971</h3>
							<p class="light lines-3">
								Whitlam lands in Beijing on a historic trip to begin establishing diplomatic relations with the People’s Republic of China.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelRight from '@/components/PanelRight.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Quote from '@/components/Quote.vue';
import Impact from '@/components/Impact.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		LightboxButton,
		Impact, Quote, DidYouKnow, PanelRight,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(63);
			height: r(1164);
			position: absolute;
			top: 0;
			left: 40%;
			z-index: 2;
			background: url(~@/assets/images/timeline-16.svg) 0 0 no-repeat;
		}
	}
}
</style>
