import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Waypoint = _resolveComponent("Waypoint")!

  return (_openBlock(), _createBlock(_component_Waypoint, {
    options: {rootMargin: '0px 0px -10% 0px', threshold: .3},
    class: _normalizeClass(['reveal', {'reveal--left': _ctx.left}, {'reveal--active': _ctx.active}]),
    onChange: _ctx.reveal
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["options", "class", "onChange"]))
}