<template>
	<div class="panel11">
		<PanelLeft
			image="assets/images/11-bg.jpg"
			:pills="['Post-War Politics', 'Australian Identity', 'Labor Party']"
		>
			<slot />
			<template #body>
				Whitlam commences campaigning and soon confronts the constitutional obstacles to progressive reform.
			</template>
			<template #button>
				<LightboxButton impact dyk>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							A fair go for Australia
						</h2>

						<p>Post-war politics called for a program to build back a better, fairer Australia. The Curtin Labor Government wanted to maintain the federal government’s economic control and to pool resources in a way that would extend federal responsibility for five years after the war in the areas of health, social security, employment, First Nations affairs, rehabilitation of servicemen, and the protection of key democratic freedoms.</p>
						<p>Whitlam adamantly supported and advocated for the proposal. And, given his father drafted the 1944 referendum terms as Commonwealth Solicitor General, Gough Whitlam understood both sides of the issue. The conservative parties and commentators argued the proposal was actually a form of centralised socialist control in Australia, and ultimately, the referendum failed.</p>
						<p>Whitlam recalled the experience of his first political campaign “had an immediate and lasting effect on [his] attitudes and career.” Despite feeling heavy disappointment, the experience helped him to appreciate the power of constitutional reform as an instrument of social progress.</p>

						<Quote class="w-75 mx-auto">
							&ldquo;The way of the reformer is hard in Australia.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['11.1-gallery.jpg', 'A citizenship ceremony in 1953. Image: National Archives of Australia.'],
								['11.2-gallery.jpg', 'Australian Labor Party Annual Membership Receipt for Edward Gough Whitlam from 1945 for the New South Wales branch. Image: Whitlam Institute Prime Ministerial Collection.'],
								['11.3-gallery.jpg', '1944 referendum campaign material from The Australian Women\'s Weekly. Image: National Library of Australia.'],
								['11.4-gallery.jpg', '1944 referendum campaign material in The Argus. Image: National Library of Australia.'],
								['11.0-panel.jpg', 'RAAF personnel voting in the 1944 referendum. Image: Australian War Memorial.'],
							]"
						/>

						<DidYouKnow>
							When in power, Whitlam initiated six referendum proposals to reform the electoral system, the structure of the federation and the economic powers of the federal government. Even after his term as prime minister, Gough Whitlam continued to advocate for constitutional reform, and for the creation of an Australian republic.
						</DidYouKnow>

						<Impact>
							<p>Whitlam wanted to rebuild the image Australia had of itself to one that was more reflective of a modern Australia. While in power, he:</p>
							<ul>
								<li>Changed the National Anthem from God Save the Queen to Advance Australia Fair,</li>
								<li>Created a new honours system, the Order of Australia, and</li>
								<li>Worked to identify, protect and conserve sites significant to Australia’s social history through the Australian Heritage Commission.</li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelLeft>
		<div class="bg-white position-relative zindex-4">
			<section class="container pt-lg-96 pb-lg-20 redline">
				<div class="row mb-lg-56">
					<div class="timelinedot col-12 col-lg-3 offset-lg-2">
						<Reveal left>
							<h3>8 August 1945</h3>
							<p class="light lines-2">
								Whitlam joins the Australian Labor Party.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row mb-lg-20">
					<div class="timelinedot col-12 col-lg-6 col-xxl-4 offset-lg-6">
						<Reveal>
							<h3>1946</h3>
							<p class="light lines-3">
								Whitlam graduates from the University of Sydney with a Bachelor of Arts (majoring in Classics) and a Bachelor of Laws.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-6 col-xxl-4 offset-xxl-2 px-lg-34">
						<Reveal left>
							<h3>1947</h3>
							<p class="light lines-3">
								Whitlam begins practising as a barrister representing clients referred to him by the NSW Legal Service Bureau.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelLeft from '@/components/PanelLeft.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Reveal from '@/components/Reveal.vue';
import Impact from '@/components/Impact.vue';
import Quote from '@/components/Quote.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		DidYouKnow,
		Quote,
		Reveal,
		LightboxButton,
		PanelLeft, Impact, Gallery,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(140);
			height: r(1511);
			position: absolute;
			top: r(-85);
			left: 45%;
			z-index: 2;
			background: url(~@/assets/images/timeline-10.svg) 0 0 no-repeat;
		}
	}
}
</style>
