<template>
	<div class="panel20">
		<PanelRight
			:pills="['Legacy', 'Australia in the World']"
			image="assets/images/20-bg.jpg"
		>
			<slot />
			<template #body>
				Australia formed Whitlam, and in turn he transformed the nation.
			</template>
			<template #button>
				<LightboxButton dyk impact>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							Eternal, not immortal
						</h2>

						<p>Margaret, Gough’s partner of many decades, died on March 17, 2012.</p>
						<p>Gough Whitlam lived to 98 years old, passing on October 21, 2014.</p>
						<p>The state memorial held at Sydney Town Hall on November 5, 2014, was attended by more than 2000 mourners, while tens of thousands more watched the televised service. RAAF fighter jets flew overhead paying tribute to Whitlam, one of its veterans.</p>
						<p>Representatives of the Gurindji people, to whom Whitlam had returned traditional lands in 1975, travelled to Sydney to attend the service. Whitlam earned the title, ‘Kulum Whitlam’, accorded to only the most respected deceased of the Gurindji.</p>
						<p class="mb-0">
							The public mourned the loss of a life devoted to transforming the nation, prompting discussion about the scale of change that is possible within.
						</p>


						<Quote class="w-75 mx-auto">
							&ldquo;I never said I was immortal, merely eternal.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['21.1-gallery.jpg', 'Then prime minister Tony Abbott (centre), with former prime ministers Malcolm Fraser, Julia Gillard, Bob Hawke, John Howard, Kevin Rudd and Paul Keating assemble for a photograph after Gough Whitlam’s state memorial service at the Sydney Town Hall on November 5, 2014.  Image: Australian Associated Press.'],
								['21.2-gallery.jpg', 'Jimmy Wavehill from Wavehill Station in the Northern Territory greeted by Warren Snowden, Member for Lingiari, outside of Gough Whitlam’s State Memorial service. Image: Australian Associated Press.'],
								['21.3-gallery.jpg', 'Mourners express their thanks outside Gough Whitlam\'s State Memorial service at Sydney Town Hall, November 5, 2014. Image: Sally Tsoutas, Western Sydney University.'],
								['21.4-gallery.jpg', 'Crowd gathers outside Gough Whitlam\'s memorial service at Sydney Town Hall, November 5, 2014. Image: Sally Tsoutas, Western Sydney University.'],
								['21.5-gallery.jpg', 'Memorial service at Sydney Town Hall, November 5, 2014. Image: Wikimedia Commons user Wikiain.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								Australian singers Paul Kelly and Kev Carmody performed a rendition of From Little Things Big Things Grow at Gough Whitlam’s funeral, which the musicians wrote together in 1991 as an expression of the Gurindji people’s struggle for equality and land rights.
							</p>
						</DidYouKnow>

						<Impact>
							<p>The experiences of the first half of Whitlam’s life were the genesis of the political agenda of the second. Equality of opportunity, empowerment of women, new migrants and Indigenous Australians. Equitable health, education, housing and transport services in Australia’s growing cities. A functional federation, an independent foreign policy, an end to the remaining ties to Britain and a fair electoral system. Whitlam made these the platform for Labor's return to power, and the agenda for his government. </p>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelRight>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 col-xl-3 offset-xl-2">
						<Reveal left>
							<h3>17 March 2012</h3>
							<p class="light lines-2 mb-lg-0">
								Margaret Whitlam passes away at the age of 92.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 col-xl-4 offset-lg-6">
						<Reveal>
							<h3>21 October 2014</h3>
							<p class="light lines-1">
								Whitlam passes away at the age of 98.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row pt-lg-6">
					<div class="timelinedot col-12 col-lg-5 col-xl-4 offset-xl-1">
						<Reveal left>
							<h3>5 November 2014</h3>
							<p class="light lines-3">
								Whitlam’s state memorial service is attended by 2,000 mourners. Tens of thousands of people watch the televised service outside.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import DidYouKnow from '@/components/DidYouKnow.vue';
import Impact from '@/components/Impact.vue';
import Quote from '@/components/Quote.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import PanelRight from '@/components/PanelRight.vue';
import Reveal from '@/components/Reveal.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		Reveal,
		PanelRight,
		LightboxButton,
		Quote,
		Impact, DidYouKnow,
	},
};
</script>

<style lang="scss" scoped>
.panel20 {
	&__2014 {
		@include media-breakpoint-up(lg) {
			margin-top: r(24);
		}
	}
}

.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(68);
			height: r(1652);
			position: absolute;
			top: r(-170);
			left: 42%;
			z-index: 2;
			background: url(~@/assets/images/timeline-19.svg) 0 0 no-repeat;
		}
	}
}
</style>
