import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-210e134c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel panel--left position-relative pt-96 pt-lg-0" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "row panel__body position-relative zindex-5" }
const _hoisted_6 = { class: "col-12 col-lg-8 col-xxl-7 offset-lg-1 zindex-2 bg-white" }
const _hoisted_7 = { class: "row justify-content-center position-relative" }
const _hoisted_8 = { class: "col-12 col-lg-11 py-lg-56 px-lg-72" }
const _hoisted_9 = { class: "mt-5 mb-3 my-lg-34" }
const _hoisted_10 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = {
  key: 0,
  class: "col-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StaticImage = _resolveComponent("StaticImage")!
  const _component_Pill = _resolveComponent("Pill")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_StaticImage, { src: _ctx.image }, null, 8, ["src"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h2", _hoisted_9, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              (_ctx.pills.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pills, (pill) => {
                      return (_openBlock(), _createBlock(_component_Pill, {
                        key: pill,
                        class: "mb-2"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(pill), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.$slots.button ? 'col-10' : 'col-12'])
                }, [
                  _createElementVNode("p", null, [
                    _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                  ])
                ], 2),
                (_ctx.$slots.button)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _renderSlot(_ctx.$slots, "button", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}