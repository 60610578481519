<template>
	<div class="panel19">
		<PanelLeft
			:pills="['After Politics']"
			image="assets/images/19-bg.jpg"
		>
			<slot />
			<template #body>
				Whitlam remained actively involved in advocacy for constitutional reform following his political retirement, serving the Australian public in a wide range of roles.
			</template>
			<template #button>
				<LightboxButton impact dyk>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							Elder Statesman
						</h2>

						<p>After politics, Gough Whitlam remained a prominent spokesperson for constitutional reform, pushing for the establishment of an Australian republic. He advocated alongside his unlikely ally, Malcolm Fraser (Australia’s 22nd Prime Minister and leader of the Liberal party), for social causes like diversity of media ownership, Aboriginal and Torres Strait Islander land rights, and refugees.</p>
						<p>In 2000, Western Sydney University’s Whitlam Institute was founded. Whitlam remained closely involved in the Prime Ministerial archive and public policy institute that continues to advance the causes he championed. His commitment continued well into his 90s, never turning down a school visit, community event or public speaking engagement.</p>
						<p class="mb-0">
							Whitlam maintained his passion for writing, publishing ten books: ‘The Truth of the Matter’ recounted his experience of the dismissal, ‘The Whitlam Government 1972-1975’ is a testament to his government’s achievements, and ‘Abiding Interests’ outlined the need for progress on a range of uncompleted reforms.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;As you know, I plan for the ages, not just for this life.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['20.1-gallery.jpg', 'Margaret and Gough Whitlam supporting Australia’s bid to host the 2000 Olympic Games in Sydney. Image: Whitlam Prime Ministerial Collection.'],
								['20.2-gallery.jpg', 'Gough and Margaret Whitlam addressing a function at the Whitlam Institute, 2002. Image: Whitlam Prime Ministerial Collection.'],
								['20.3-gallery.jpg', 'Gough and Margaret Whitlam at the International Women’s Year 30th Anniversary Dinner at Old Parliament House, 2005. Image: National Library of Australia.'],
								['20.4-gallery.jpg', 'Gough Whitlam with Eric Sidoti visiting the Whitlam Prime Ministerial Collection, 2013. Image: Sally Tsoutas, Western Sydney University.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								The Whitlams’ influence, enthusiasm, and wide network of international contacts were optimised as part of the Sydney 2000 Olympic Games bid team. Their efforts helped to secure the Olympics for Sydney (defeating Beijing in the deciding ballot by a single vote!).
							</p>
						</DidYouKnow>

						<Impact>
							<p>Whitlam served on the governing bodies for a wide range of organisations, including:</p>
							<ul>
								<li>The Australia China Council,</li>
								<li>National Gallery of Australia,</li>
								<li>Constitutional Commission,</li>
								<li>World Heritage Committee,</li>
								<li>General Assembly of the UNESCO World Heritage Convention,</li>
								<li>UNESCO World Heritage Committee,</li>
								<li>University of Sydney Senate, and</li>
								<li>Independent Commission on International Humanitarian Issues.</li>
								<li>From 1985, as a member of the Constitutional Commission, he advocated for:</li>
								<li>First Nations’ peoples and their land rights, for human rights, for fixed four-year electoral terms, and national disability insurance.</li>
								<li>He lobbied for the reform of the powers of the Governor General and Senate to prevent a repeat of the constitutional crisis that led to the controversial dismissal of his government in 1975.</li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelLeft>
		<div class="bg-white position-relative zindex-4">
			<section class="container pt-lg-96 pb-lg-40 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 col-xl-4 offset-xl-1 mb-lg-56">
						<Reveal left>
							<h3>1993</h3>
							<p class="light mb-0 lines-3">
								Whitlam and Margaret join the Sydney 2000 Olympic Games bid team to help secure the Olympics for Sydney.
							</p>
						</Reveal>
					</div>
				</div>
				<div class="row mt-2">
					<div class="timelinedot col-12 col-lg-5 col-xl-4 offset-lg-6 ps-lg-20 ps-xl-0">
						<Reveal>
							<h3>2000</h3>
							<p class="light lines-4">
								The Whitlam Institute within Western Sydney University is established as a Prime Ministerial archive and public policy institute to uphold the Whitlam legacy.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import DidYouKnow from '@/components/DidYouKnow.vue';
import Impact from '@/components/Impact.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import PanelLeft from '@/components/PanelLeft.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		Quote,
		Reveal,
		PanelLeft,
		LightboxButton,
		Impact, DidYouKnow,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(249);
			height: r(1561);
			position: absolute;
			top: r(-200);
			left: 40%;
			z-index: 2;
			background: url(~@/assets/images/timeline-18.svg) 0 0 no-repeat;
		}
	}
}
</style>
