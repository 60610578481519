<template>
	<div class="panel12">
		<PanelRight
			:pills="['Family', 'Housing', 'Urban Development']"
			image="assets/images/09-bg.jpg"
		>
			<slot />
			<template #body>
				The end of the war saw servicemen and women try to re-establish the lives they had left behind.
			</template>
			<template #button>
				<LightboxButton impact dyk>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							Life in the suburbs
						</h2>

						<p>At the end of the war, in 1945, the Whitlams bought land in Wangi Avenue, Cronulla, one of the rapidly growing outer suburbs of Sydney. With just a £200 war service home loan, they began building a house for their growing family.</p>
						<p>In 1946, Gough Whitlam completed his law degree, and in 1947 began working hard to build his practice as a barrister. His work from the NSW Legal Service Bureau brought him into contact with a lot of underprivileged clients involved in tenancy disputes. This was a common and distressing legal area for many low-paid working Australians.</p>
						<p class="mb-0">
							After winning the Australian National Quiz in both 1948 and 1949, Gough Whitlam caught the eye of Prime Minister Ben Chifley.
						</p>

						<Quote class="w-50 mx-auto">
							&ldquo;A family’s standard of living was determined by where it lived as much as by its income.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['12.1-gallery.jpg', 'Gough Whitlam (far right) as a practicing lawyer, 1951. Image: Whitlam Prime Ministerial Collection.'],
								['12.2-gallery.jpg', 'Gough and Margaret Whitlam with their first child,  Antony, 1944.  Image: Whitlam Prime Ministerial Collection.'],
								['12.4-gallery.jpg', 'Gough Whitlam competing in the Australian National Quiz, 1949. Image: Whitlam Prime Ministerial Collection.'],
								['12.5-gallery.jpg', 'Whitlam’s quiz success noted in The West Australian, 30 September, 1949. Image: National Library of Australia.'],
								['12.0-panel.jpg', 'Gough Whitlam with sons Nicholas and Antony at their Cronulla home in the early 1950s. Image: Whitlam Prime Ministerial Collection.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								The Whitlams had four children. Antony, their eldest son, born in 1944. Another son, Nicholas, born in 1945; followed by Stephen in 1950, and their only daughter, Catherine, in 1954.
							</p>
						</DidYouKnow>

						<Impact>
							<ul>
								<li>The Legal Service Bureau was one of the first and few free legal aid services provided to the underprivileged, serving over a million clients between 1942 and 1949. </li>
								<li>Whitlam’s legal work convinced him of the need for a more comprehensive legal aid system. So much so that when he became Prime Minister, he introduced the Australian Legal Aid Office - the largest legal aid service in the country. </li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelRight>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-4 offset-lg-2 col-xxl-3 offset-xxl-3">
						<Reveal left>
							<h3>29 November 1952</h3>
							<p class="light lines-3">
								Gough Whitlam wins the by-election in Werriwa and enters federal parliament.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelRight from '@/components/PanelRight.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Impact from '@/components/Impact.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Quote from '@/components/Quote.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		Quote,
		LightboxButton,
		Impact,
		DidYouKnow, PanelRight,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(63);
			height: r(1351);
			position: absolute;
			top: r(-14);
			left: 50%;
			z-index: 2;
			background: url(~@/assets/images/timeline-11.svg) 0 0 no-repeat;
		}
	}
}
</style>
