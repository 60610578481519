<template>
	<div class="school bg-white position-relative zindex-5">
		<kinesis-container tag="section" class="zindex-6 position-relative">
			<BrownishSegment
				heading="Multicultural Australia"
				image="assets/images/14-bg.jpg"
				layout="left"
			>
				The Whitlams joined millions of families in the outer suburbs, which became the heart of Australia’s emerging Multicultural society.
				<template #button>
					<LightboxButton dyk impact>
						<template #main>
							<h2 id="main" class="mb-lg-40">
								Multicultural Australia
							</h2>

							<p>The Whitlam family relocated to 32 Albert Street, Cabramatta, in Western Sydney, November 1957. The post-war demographic changes in this neighbourhood reflected the experience across Australia, with many newly arrived non-English speaking migrants pressured to leave their culture behind in favour of the dominant Anglo-Saxon culture. At the time, Australia’s ‘White Australia Policy’ was still in place, something which Gough Whitlam detested.</p>
							<p class="mb-0">
								The area became home to many Greek and Italian migrants in the late 1960s. Whitlam’s interest in classical studies and principled rejection of racial prejudice meant he had profound respect for their cultural heritages and saw cultural diversity as something to be celebrated as a positive contribution to Australian society. Whitlam’s cultural appreciation and acceptance helped him to forge strong bonds with migrant groups within his community.
							</p>

							<Quote class="w-75 mx-auto">
								&ldquo;The entire world believes that our immigration policy is based on colour... The true patriot therefore will not seek to justify and prolong these policies but will seek to change them.&rdquo;
							</Quote>

							<Gallery
								:imagery="[
									['14.1-gallery.jpg', 'A group of new migrants to Australia help each other to learn and practice English. Image: National Archives of Australia.'],
									['14.2-gallery.jpg', 'Young new Australians taking part in language lessons. Image: National Archives of Australia.'],
									['14.3-gallery.jpg', 'Speech given by Gough Whitlam at the All Nations Grand Ball held at Fitzroy Town Hall, on 7 November, 1975. Image: Whitlam Prime Ministerial Collection.'],
									['14.0-panel.jpg', 'Italian immigrants arriving in Sydney in 1951. Image: National Archives of Australia.'],
								]"
							/>

							<DidYouKnow>
								<p class="mb-0">
									Spaghetti Bolognese became Margaret Whitlam’s staple dish. Many newly arrived Italian migrants would give thanks to the Whitlams on weekend constituency visits in the form of their home-grown fresh tomatoes. Margaret would use the gifted ingredients to make the dish on election night for crowds of well-wishers and journalists.
								</p>
							</DidYouKnow>

							<Impact>
								<ul>
									<li>Whitlam ensured Labor withdrew any support of the White Australia Policy.  </li>
									<li>As Prime Minister, he was able to replace the outdated policy with one of multiculturalism that expanded support services for new migrants, and outlawed discrimination based on race or ethnicity with the Racial Discrimination Act. </li>
									<li>The Whitlam Government introduced the Racial Discrimination Act 1975, one of the most important human rights protections in Australia. The legislation made it unlawful to unfairly discriminate against someone based on their ethnicity or national origin. </li>
								</ul>
							</Impact>
						</template>
					</LightboxButton>
				</template>
			</BrownishSegment>
		</kinesis-container>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-6 offset-lg-6 col-xxl-4 ps-lg-56">
						<Reveal>
							<h3>1957</h3>
							<p class="light lines-2 mb-lg-0">
								The Whitlams connect to the multicultural community in western Sydney.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import BrownishSegment from '@/components/BrownishSegment.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Impact from '@/components/Impact.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Quote from '@/components/Quote.vue';
import Reveal from '@/components/Reveal.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		Quote,
		Reveal,
		LightboxButton, Impact, DidYouKnow, BrownishSegment,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(128);
			height: r(1140);
			position: absolute;
			top: r(-60);
			left: 44%;
			z-index: 2;
			background: url(~@/assets/images/timeline-13.svg) 0 0 no-repeat;
		}
	}
}
</style>
