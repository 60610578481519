
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		panel: {
			type: String,
			default: 'panel_1',
		},
	},
	data: () => ({
		tooltipX: 0,
		tooltips: [
			{
				label: '1916', visible: true, tooltip: 'Introduction',
			},
			{
				label: '1920', visible: true, tooltip: 'Early Life',
			},
			{
				label: '1920', visible: false, tooltip: 'Moving to Canberra',
			},
			{
				label: '1930', visible: true, tooltip: 'Canberra School',
			},
			{
				label: '1940', visible: true, tooltip: 'Higher Education',
			},
			{
				label: '1940', visible: false, tooltip: 'War & Peace',
			},
			{
				label: '1940', visible: false, tooltip: 'World War II',
			},
			{
				label: '1940', visible: false, tooltip: 'Exposure to Injustice',
			},
			{
				label: '1940', visible: false, tooltip: 'Margaret',
			},
			{
				label: '1940', visible: false, tooltip: 'Combating Colonialism',
			},
			{
				label: '1940', visible: false, tooltip: ' A Fairer Australia ',
			},
			{
				label: '1950', visible: true, tooltip: 'Suburbia',
			},
			{
				label: '1950', visible: false, tooltip: 'Post War',
			},
			{
				label: '1950', visible: false, tooltip: 'Multicultural Australia',
			},
			{
				label: '1960', visible: true, tooltip: 'A Labor Star',
			},
			{
				label: '1970', visible: true, tooltip: 'Feminism',
			},
			{
				label: '1970', visible: false, tooltip: 'Foreign Relations ',
			},
			{
				label: '1970', visible: false, tooltip: 'It’s Time',
			},
			{
				label: '1975', visible: true, tooltip: 'Spokesperson',
			},
			{
				label: 'Post Political life', visible: false, tooltip: 'Elder Statesman',
			},
			{
				label: 'Post Political life', visible: false, tooltip: 'Eternal Impact',
			},
		],
	}),
	computed: {
		activeTooltip() {
			const index = Number.parseInt(this.panel.replace('panel_', ''));
			return this.tooltips[index - 1];
		},
	},
	watch: {
		panel (newValue, oldValue) {
			if (newValue !== oldValue) {
				this.moveTooltip(newValue);
			}
		},
	},
	mounted() {
		window.addEventListener('resize', this.onResize);
		this.moveTooltip(this.panel);

		let observer = new IntersectionObserver(([entry], observer) => {
			const timeline = this.$refs.timeline as HTMLElement;
			if (entry.intersectionRatio < 1 && entry.boundingClientRect.top < 0) {
				const { clientHeight: height, style } = timeline;
				const width = (this.$refs.outer as HTMLElement).clientWidth;

				style.position = 'fixed';
				style.top = '0';
				style.width = `${width}px`;
				style.height = `${height}px`;
			} else {
				timeline.style.position = 'static';
				timeline.style.top = 'auto';
			}

		}, { threshold: [0.0001, 1] });

		const element = document.querySelector('.timeline-outer');
		if (element) {
			observer.observe(element);
		}
	},
	unmounted() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		moveTooltip (panelId: string) {
			if (this.$refs[panelId] !== undefined) {
				const element = (this.$refs[panelId] as Array<HTMLElement>)[0];
				this.tooltipX = element.offsetLeft + (element.clientWidth / 2);
			}
		},
		slideToPanel (pandelIndex: number) {
			const panelId = `panel_${pandelIndex}`;
			this.$emit('selected', panelId);
			this.moveTooltip(panelId);
			if (typeof window !== 'undefined') {
				const timeline = this.$refs.timeline as HTMLElement;
				const { offsetTop: top } = document.querySelector(`#${panelId}`) as HTMLElement;
				window.scrollTo({
					top: top - timeline.clientHeight,
					behavior: 'smooth',
				});
			}
		},
		onResize () {
			const timeline = this.$refs.timeline as HTMLElement;
			const { style } = timeline;
			if (style.position === 'fixed') {
				const width = (this.$refs.outer as HTMLElement).clientWidth;
				style.width = `${width}px`;
			}
		},
	},
});
