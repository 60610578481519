
import { defineComponent, PropType } from 'vue';
import StaticImage from '@/components/StaticImage.vue';
import Pill from '@/components/Pill.vue';

export default defineComponent({
	components: { Pill, StaticImage },
	props: {
		pills: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		image: {
			type: String,
			required: true,
		},
	},
});
