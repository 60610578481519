
import { defineComponent } from 'vue';
import Btn from '@/components/Btn.vue';

export default defineComponent({
	props: {
		src: {
			type: String,
			required: true,
		},
	},
	computed: {
		source() {
			return require(`@/${this.src}`);
		},
	},
	mounted() {
		// let observer = new IntersectionObserver(([entry], observer) => {
		// 	const duplicate = this.$refs.duplicate as HTMLElement;
		// 	const origin = this.$refs.origin as HTMLElement;
		// 	console.log(entry.intersectionRatio);
		// 	console.log(origin.getBoundingClientRect().top);
		// 	if (entry.intersectionRatio === 1 && entry.boundingClientRect.top >= 0 && origin.getBoundingClientRect().top <= 150) {
		// 		const left = origin.getBoundingClientRect().left;
		// 		const { clientWidth } = origin;
		//
		// 		duplicate.style.position = 'fixed';
		// 		duplicate.style.top = '150px';
		// 		duplicate.style.left = `${left}px`;
		// 		duplicate.style.width = `${clientWidth}px`;
		// 	}
		// 	// else if (entry.intersectionRatio >= 0.9 && entry.intersectionRatio <= 1 &&  entry.boundingClientRect.top >= 0) {
		// 	// 	duplicate.style.position = 'static';
		// 	// 	duplicate.style.top = '0';
		// 	// 	duplicate.style.left = '0';
		// 	// 	duplicate.style.width = 'auto';
		// 	// }
		// }, { threshold: [0, 1], rootMargin: '75px 0px 75px 0px' });
		// window.addEventListener('load', () => {
		// 	observer.observe(this.$refs.origin as HTMLElement);
		// });


		window.addEventListener('load', () => {
			this.scroll();
			window.addEventListener('scroll', () => {
				this.scroll();
			});
		});
	},
	methods: {
		scroll() {
			if (window.innerWidth < 1024) {
				return;
			}

			const timeline = document.querySelector('.timeline') as HTMLElement;
			const duplicate = this.$refs.duplicate as HTMLElement;
			const origin = this.$refs.origin as HTMLElement;
			const yAxisTrehsold = timeline.getBoundingClientRect().top + timeline.getBoundingClientRect().height;

			const { top: originY } = origin.getBoundingClientRect();
			if (originY <= yAxisTrehsold) {
				duplicate.style.display = 'block';
				// duplicate.style.position = 'fixed';
				duplicate.style.top = `${yAxisTrehsold}px`;
				// duplicate.style.left = `${origin.getBoundingClientRect().left}px`;
				// duplicate.style.width = `${origin.getBoundingClientRect().width}px`;
				// duplicate.style.left = '50%';
				// duplicate.style.transform = 'translateX(-50%)';
				// duplicate.style.width = '100vw';
				origin.style.opacity = '0';
			} else {
				duplicate.style.display = 'none';
				origin.style.opacity = '1';
			}
		},
	},
});
