
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		red: {
			type: Boolean,
			default: false,
		},
	},
});
