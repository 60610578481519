<template>
	<div class="panel13">
		<PanelLeft
			image="assets/images/13-bg.jpg"
			:pills="['Housing', 'Post-War Politics', 'Labor Party']"
		>
			<slot />
			<template #body>
				In post-war Australia, cities experienced suburban sprawl, cutting many Australians off from access to essential services and well-served standards of living.
			</template>
			<template #button>
				<LightboxButton impact dyk>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							Post-war suburban sprawl
						</h2>

						<p>As Australia emerged from the war, the booming population and rising cost of living forced many Australians to relocate to the outer fringes of major cities. As cities expanded and new suburbs hastily created, many homes were underserved with inadequate public services, including no sewerage lines, plus the lack of facilities, such as community centres and libraries. They were also under-represented in an inequitable electoral system. Whitlam was firm in his belief “that a family’s standard of living was determined by where it lived as much as by its income.”</p>
						<p>Whitlam’s political career officially began in 1952, when he was finally elected as the federal Labor member for Werriwa, after two unsuccessful local and one unsuccessful state government election campaigns. Whitlam won the seat with a record majority of 66% of the vote and celebrated by buying the family’s first car and a hat – an essential part of a politician’s outfit.</p>
						<p class="mb-0">
							Gough Whitlam was very community minded. He joined the Suburban Progress Association, the local Returned Services’ League (RSL), the P&C at South Cronulla Public School, as well as helping to establish a local children’s library.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;It was said of Caesar Augustus that he found Rome brick and left it marble. It will be said of Gough Whitlam that he found the outer suburbs of Sydney... and left them fully flushed.&rdquo;
							<template #author>
								Neville Wran, former NSW Premier.
							</template>
						</Quote>

						<Gallery
							:imagery="[
								['13.1-gallery.jpg', 'Advertising for a suburban home in the post war period. Image: Sydney Living Museums.'],
								['13.2-gallery.jpg', 'Gough Whitlam with sons Stephen, Nicholas and Antony on the steps of their Cronulla home in October 1951. Image: Whitlam Prime Ministerial Collection.'],
								['13.3-gallery.jpg', 'Deputy Labor Leader Gough Whitlam with Labor leader Arthur Calwell in 1960. Image: National Library of Australia.'],
								['13.4-gallery.jpg', 'Gough Whitlam at the official opening of Fairfield Post Office on 20 March 1961. Image: National Archives of Australia.'],
								['13.0-panel.jpg', 'Many suburban homes were rapidly built in the outer suburbs of major cities in the post-war period. Image: National Archives of Australia.'],
							]"
						/>

						<DidYouKnow>
							<p class="mb-0">
								Whitlam did not have an official electorate office, so his family home became the place for constituents to come and be welcomed and fed by Margaret, while he listened and learned.
							</p>
						</DidYouKnow>

						<Impact>
							<ul>
								<li>Whitlam’s passion for equitable representation in parliament was evident in his electoral reform legislation that enshrined the ‘One Vote One Value’ principle.</li>
								<li>As the first prime minister from the outer suburbs, Whitlam’s government set about providing more adequate services to households, such as improved transport systems, sewerage programs and community spaces, for these underserved areas. His introduction of government-run land commissions also meant more affordable housing for young families.</li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelLeft>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-6 offset-lg-1 col-xxl-4 offset-xxl-3">
						<Reveal left>
							<h3>1957</h3>
							<p class="light lines-2 mb-0">
								The Whitlams move from Cronulla out to Cabramatta, in the western suburbs of Sydney.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelLeft from '@/components/PanelLeft.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Impact from '@/components/Impact.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Reveal from '@/components/Reveal.vue';
import Quote from '@/components/Quote.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Gallery,
		Quote,
		Reveal,
		LightboxButton, Impact, DidYouKnow, PanelLeft,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(128);
			height: r(791);
			position: absolute;
			top: r(-60);
			left: 55%;
			z-index: 2;
			background: url(~@/assets/images/timeline-12.svg) 0 0 no-repeat;
		}
	}
}
</style>
