
import './assets/styles/default.scss';
import { defineComponent } from 'vue';
import { Waypoint } from 'vue-waypoint';
import Timeline from '@/components/Timeline.vue';
import Intro from '@/components/sections/Intro.vue';
import One from '@/components/sections/01.vue';
import Two from '@/components/sections/02.vue';
import Three from '@/components/sections/03.vue';
import Four from '@/components/sections/04.vue';
import Five from '@/components/sections/05.vue';
import Six from '@/components/sections/06.vue';
import Seven from '@/components/sections/07.vue';
import Nine from '@/components/sections/09.vue';
import Ten from '@/components/sections/10.vue';
import Eleven from '@/components/sections/11.vue';
import Twelve from '@/components/sections/12.vue';
import Thirteen from '@/components/sections/13.vue';
import Fourteen from '@/components/sections/14.vue';
import Fifteen from '@/components/sections/15.vue';
import Sixteen from '@/components/sections/16.vue';
import Seventeen from '@/components/sections/17.vue';
import Eighteen from '@/components/sections/18.vue';
import Nineteen from '@/components/sections/19.vue';
import Twenty from '@/components/sections/20.vue';
import TwentyOne from '@/components/sections/21.vue';
import Epilogue from '@/components/sections/Epilogue.vue';
import ContentWarning from '@/components/ContentWarning.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
	components: {
		Footer,
		ContentWarning,
		Nine,
		Seven,
		Six,
		Five,
		Four,
		Intro,
		Three,
		Two,
		One,
		Timeline,
		Waypoint,
		Ten,
		Eleven,
		Twelve,
		Thirteen,
		Fourteen,
		Fifteen,
		Sixteen,
		Seventeen,
		Eighteen,
		Nineteen,
		Twenty,
		TwentyOne,
		Epilogue,
	},
	data: () => ({
		activePanel: 'panel_1',
		tops: {} as any,
		waypointOptions: { rootMargin: '150px 0px 150px 0px', threshold: 0.5 },
		scrollEndTimeout: 0,
		isUserJumping: false,
	}),
	mounted() {
		window.addEventListener('scroll', this.onscroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.onscroll);
		window.removeEventListener('scroll', this.onUserTriggeredScroll);
	},
	methods: {
		onscroll () {
			if (window.innerWidth < 1024) {
				return;
			}

			const elements = document.querySelectorAll('.bg-parallax, .parallax');
			for (const element of elements) {
				if (element) {
					const reverse = element.classList.contains('reverse');
					const strength = Number.parseInt(element.getAttribute('data-strength') ?? '8');
					const movement = element.getAttribute('data-movement') ?? 'relative';
					const transition = element.getAttribute('data-transition') ?? 'none';

					if (movement === 'relative') {
						const modifier = element.getBoundingClientRect().top / strength;
						let y = Math.round(modifier * 100) / 100;
						y *= reverse ? -1 : 1;
						const [posX] = getComputedStyle(element).backgroundPosition.split(' ');
						(element as HTMLElement).style.backgroundPosition = posX + ' ' + y + 'px';
						if (transition === 'scale') {
							(element as HTMLElement).style.backgroundPosition = 'center';
							(element as HTMLElement).style.backgroundSize = `${100 + Math.abs(y/8)}%`;
						}
					} else {
						const modifier =  Math.abs(document.documentElement.scrollTop - (element as HTMLElement).offsetTop) / strength;
						let y = Math.round(modifier * 100) / 100;
						y *= reverse ? 1 : -1;
						(element as HTMLElement).style.transform = `translate(0, ${y}px)`;
					}
				}
			}
		},
		onWaypoint (state: {going:string, direction: string}, panelId: string) {
			// let panelsCount = 0;
			// for (const key in this.$refs) {
			// 	if (key.startsWith('panel_')) {
			// 		panelsCount++;
			// 	}
			// }

			// If the scroll was triggered by user clicking on timeline, do not fire waypoints.
			// This is performance enhancement -> the intersection observer does not fire waypoints as the
			// page is being scrolled down/up to the selected panel.
			if (this.isUserJumping) {
				return;
			}

			if (state.going === 'IN') {
				this.activePanel = panelId;
			}
			return;
			// if (state.going === 'IN') {
			// 	this.activePanel = panelId;
			// } else if (this.activePanel === panelId && state.going === 'OUT') {
			// 	// Fix of an event when one panel is still visible but entering another one
			// 	// This caused tooltip to be on wrong position
			// 	const index = Number.parseInt(this.activePanel.replace('panel_', ''));
			// 	if (state.direction === 'DOWN') {
			// 		if (index > 1) {
			// 			this.activePanel = `panel_${index - 1}`;
			// 		}
			// 	} else if (state.direction === 'UP' && index + 1 <= panelsCount) {
			// 		this.activePanel = `panel_${index + 1}`;
			// 	}
			// }
		},
		timelineUserSelect (panelId: string) {
			this.isUserJumping = true;
			this.activePanel = panelId;
			window.addEventListener('scroll', this.onUserTriggeredScroll);
		},
		onUserTriggeredScroll () {
			clearTimeout(this.scrollEndTimeout);
			this.scrollEndTimeout = setTimeout(() => {
				this.isUserJumping = false;
			}, 150);
		},
	},
});
