import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2be48bc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "parallaxy" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      ref: "origin",
      class: "parallaxy__origin",
      src: _ctx.source
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      ref: "duplicate",
      class: "parallaxy__duplicate",
      src: _ctx.source
    }, null, 8, _hoisted_3)
  ]))
}