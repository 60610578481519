<template>
	<kinesis-container tag="section">
		<BrownishSegment
			heading="End of the empire "
			image="assets/images/10-bg.jpg"
			layout="left"
		>
			War exposed Australian servicemen and women to the disempowering effect of colonialism on many countries, recalibrating public support for the existing global order.
			<template #button>
				<LightboxButton
					dyk
					impact
				>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							End of the empire
						</h2>

						<p>Throughout Gough Whitlam’s tours of duty in the Pacific, he encountered the racist and disempowering effects of colonialism. This compelled him to question Australia’s own colonial legacy, which in turn shaped his outlook on the future of Australia’s foreign policy.</p>
						<p class="mb-0">
							Having witnessed the first meeting of the Philippine Congress on June 9, 1945, Whitlam was struck by the country’s transformation from a colonial possession of Spain, and later the United States, to a self-governing independent nation. Attendance at such historically significant events gave Whitlam “insight which nobody in the Parliament had so well” and confirmed his belief that there was no place for colonialism in the new world order.
						</p>

						<Quote class="w-75 mx-auto">
							&ldquo;There is no place for small-mindedness, for harking back to a colonial period we should have left well behind us.&rdquo;
						</Quote>

						<Gallery
							:imagery="[
								['10.1-gallery.jpg', 'Prime Ministers Gough Whitlam and Michael Somare at Papua New Guinea Independence Day Celebrations. Image: National Archives of Australia.'],
								['10.2-gallery.jpg', 'The United States\' flag is lowered in Manila as the Philippines flag is raised on July 4, 1946. Image: Philippine Presidential Museum and Library.'],
								['10.3-gallery.jpg', 'Map highlighting colonialism around the world. Created by David Rumsey.'],
								['10.4-gallery.jpg', 'Philippine President Osmeña delivers the first State of the Nation Address on June 9, 1945.  Image: Philippine Presidential Museum and Library.'],
								['10.5-gallery.jpg', 'Gough Whitlam during the celebrations for the independence of Papua New Guinea, in 1975. Image: National Archives of Australia.'],

							]"
						/>

						<DidYouKnow>
							Colonialism remained rife all throughout the Pacific at the time of WWII. Whitlam noted that he saw “Australian imperialism at Milne Bay and Manus in Papua New Guinea, Dutch imperialism in Merauke, Hollandia (now Jayapura), Biak and Morotai, the relics of Spanish, German, Japanese and American imperialism in Palau and, above all, American imperialism in Leyte, Luzon and other islands in the Philippines”.
						</DidYouKnow>

						<Impact>
							<ul>
								<li>When in power, Whitlam worked with the Papua New Guinean leaders to end Australia’s colonial control of their country and bring about the independent nationhood in 1975.</li>
								<li>He further crafted a post-colonial Australian foreign policy that positioned Australia within a community of equals of Asia-Pacific nations.</li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</BrownishSegment>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row mb-lg-6">
					<div class="timelinedot col-12 col-lg-6 col-xxl-4 offset-xxl-7">
						<Reveal left>
							<h3>1945</h3>
							<p class="light lines-3">
								Returning from his tour of duty in the Pacific, Whitlam begins to question Australia’s colonial ties and foreign policy.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</kinesis-container>
</template>

<script>
import BrownishSegment from '@/components/BrownishSegment.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Impact from '@/components/Impact.vue';
import Quote from '@/components/Quote.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	components: {
		Quote,
		Impact, LightboxButton, BrownishSegment,
		DidYouKnow, Gallery,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(74);
			height: r(1390);
			position: absolute;
			top: 0;
			left: 50%;
			z-index: 2;
			background: url(~@/assets/images/timeline-09.svg) 0 0 no-repeat;
		}
	}
}
</style>
