
import { defineComponent, PropType } from 'vue';
import Btn from '@/components/Btn.vue';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper.scss';

export default defineComponent({
	components: {
		Btn, Swiper, SwiperSlide,
	},
	props: {
		imagery: {
			type: Array as PropType<string[]>,
			default: () => ([]),
		},
	},
	data: () => ({
		swiper: {
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			modules: [Navigation],
		},
	}),
});
