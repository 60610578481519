<template>
	<div>
		<PanelRight
			:pills="['Family', 'Sydney University', 'RAAF']"
			image="assets/images/08-bg.jpg"
		>
			<slot />
			<template #body>
				Whitlam met his match in Margaret Dovey in December 1939, marking the start of a romantic, complementary partnership that would go on to help change Australia.
			</template>
			<template #button>
				<LightboxButton dyk impact>
					<template #main>
						<h2 id="main" class="mb-lg-40">
							“My finest appointment”
						</h2>

						<p>The romance blossomed between Margaret Dovey and Gough Whitlam as they performed together in several university plays and revues, deepening both their respect for one another, and Whitlam’s admiration for Margaret’s independence. Margaret recorded in a 1941 diary entry of their relationship that she felt “a certain concern and interest in everything he does”.</p>
						<p>Margaret’s kept Whitlam grounded, and they made a formidable team, with Whitlam famously describing her as his “finest appointment”.</p>
						<p class="mb-0">
							On April 22, 1942, the couple were married at St Michael's Anglican Church in Vaucluse. Despite being separated just weeks after the marriage when Whitlam was deployed by the RAAF, he kept the romance alive by writing to her every second day. The letters allowed the often-reserved man to freely speak of the “continuous and constant bliss” and “unmitigated and unperturbed romance” of their relationship. The separation of active duty brought further challenges as Margaret gave birth to their first two children, Antony and Nicholas, during the periods Whitlam was deployed on active service.
						</p>

						<Quote class="w-75 mx-auto">
							&hellip;&ldquo;across a crowded room, there was this gorgeous looking man and I thought, ‘Well, he’ll do me!’… He had this beautiful dry, dry wit—and he was tall.&rdquo;
							<template #author>
								Margaret Whitlam
							</template>
						</Quote>

						<Gallery
							:imagery="[
								['9.1-gallery.jpg', 'Margaret Whitlam competed in swimming events at the British Empire Games, 1938. Image: Whitlam Prime Ministerial Collection.'],
								['9.2-gallery.jpg', 'Margaret Whitlam in the 1940s. Image: Whitlam Prime Ministerial Collection.'],
								['9.3-gallery.jpg', 'Gough and Margaret Whitlam with family on their wedding day on April 22, 1942. Image: Fairfax Limited.'],
								['9.4-gallery.jpg', 'Gough and Margaret Whitlam on a diplomatic trip to China in 1973. Image: National Archives of Australia.'],
								['9.5-gallery.jpg', 'Margaret and Gough Whitlam with the Philippines ‘First Lady’, Imelda Marcos, at the Sydney Opera House during the visit of President Ferdinand Marcos and Mrs Marcos, October 1973. Image: National Archives of Australia.'],
								['9.0-panel.jpg', 'Gough and Margaret Whitlam on their wedding day on April 22, 1942. Image: Whitlam Prime Ministerial Collection.'],
							]"
						/>

						<DidYouKnow>
							Margaret Dovey was a popular, confident, graceful, intelligent, and witty, economics student at the University of Sydney. She was an accomplished swimmer and the daughter of Supreme Court judge Wilfred Dovey.
						</DidYouKnow>

						<Impact>
							<ul>
								<li>Margaret Whitlam refused to adopt the traditional role of wife, she remained politically engaged, informed on social issues and maintained her employment.</li>
								<li>Margaret’s warm and welcoming nature complemented Whitlam’s often shy and awkward temperament, helping to broaden his political appeal, particularly with women voters.</li>
								<li>Margaret served on the Advisory Committee for the International Women’s Year, whose work led to government funding of women’s health centres, refuges, and women’s social services nationwide.</li>
							</ul>
						</Impact>
					</template>
				</LightboxButton>
			</template>
		</PanelRight>
		<div class="bg-white position-relative zindex-4">
			<section class="container py-lg-96 redline">
				<div class="row">
					<div class="timelinedot col-12 col-lg-5 offset-lg-3 col-xxl-4 offset-xxl-4">
						<Reveal left>
							<h3>1944 - 1945</h3>
							<p class="light lines-3">
								Margaret gives birth to their first two children: Antony and Nicholas, while Whitlam is deployed on active duty.
							</p>
						</Reveal>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import PanelRight from '@/components/PanelRight.vue';
import DidYouKnow from '@/components/DidYouKnow.vue';
import LightboxButton from '@/components/LightboxButton.vue';
import Reveal from '@/components/Reveal.vue';
import Impact from '@/components/Impact.vue';
import Gallery from '@/components/Gallery.vue';
import Quote from '@/components/Quote.vue';

export default {
	components: {
		Quote,
		Gallery,
		Impact,
		Reveal,
		LightboxButton,
		DidYouKnow, PanelRight,
	},
};
</script>

<style lang="scss" scoped>
.redline {
	position: relative;

	&:before {
		@include media-breakpoint-up(lg) {
			content: '';
			display: block;
			width: r(372);
			height: r(958);
			position: absolute;
			top: r(-53);
			left: 50%;
			z-index: 2;
			background: url(~@/assets/images/timeline-08.svg) 0 0 no-repeat;
		}
	}
}
</style>
