
import { Waypoint } from 'vue-waypoint';
import { defineComponent } from 'vue';

export default defineComponent({
	components: { Waypoint },
	props: {
		left: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({ active: false }),
	methods: {
		reveal (state: {going:string, direction: string}) {
			if (state.going === 'IN') {
				this.active = true;
			}
		},
	},
});
